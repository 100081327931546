var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{staticClass:"mb-2",attrs:{"cols":"12"}},[_c('h5',{staticClass:"mb-0"},[_vm._v("Lampiran data kelengkapan pelanggan.")])]),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"KTP / KITAS"}},[_c('b-form-radio-group',{attrs:{"options":_vm.fabForm.ktp == '0000-0000-0000-0000' || _vm.fabForm.passport == '000000000' || _vm.fabForm.title == 5 || _vm.fabForm.title == 6 || _vm.fabForm.title == 7 ? _vm.options_lampiran : _vm.options_lampiran_wajib,"name":"radio-ktp"},on:{"change":function($event){return _vm.clear_attachment('ktp')}},model:{value:(_vm.fabForm.lampiran_ktp),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_ktp", $$v)},expression:"fabForm.lampiran_ktp"}}),_c('br'),(_vm.fabForm.lampiran_ktp == 1)?_c('validation-provider',{attrs:{"name":"KTP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"myKtp",attrs:{"id":"ktp","name":"ktp","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingKtpEvent,"vdropzone-complete":_vm.completeKtpEvent,"vdropzone-success":_vm.uploadKtpSuccess,"vdropzone-error":_vm.errorKtpEvent,"vdropzone-removed-file":_vm.removeKtpFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.ktp != '') ? _vm.errAttachment.ktp: errors[0]))])]}}],null,false,1259246324)}):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"NPWP"}},[_c('b-form-radio-group',{attrs:{"options":_vm.fabForm.title == 5 || _vm.fabForm.title == 6 || _vm.fabForm.title == 7 ? _vm.options_lampiran_wajib : _vm.options_lampiran,"name":"radio-npwp"},on:{"change":function($event){return _vm.clear_attachment('npwp')}},model:{value:(_vm.fabForm.lampiran_npwp),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_npwp", $$v)},expression:"fabForm.lampiran_npwp"}}),_c('br'),(_vm.fabForm.lampiran_npwp == 1)?_c('validation-provider',{attrs:{"name":"NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"myNpwp",attrs:{"id":"npwp","name":"npwp","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingNpwpEvent,"vdropzone-complete":_vm.completeNpwpEvent,"vdropzone-success":_vm.uploadNpwpSuccess,"vdropzone-error":_vm.errorNpwpEvent,"vdropzone-removed-file":_vm.removeNpwpFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.npwp != '') ? _vm.errAttachment.npwp: errors[0]))])]}}],null,false,1726700212)}):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"SIUP"}},[_c('validation-provider',{attrs:{"name":"SIUP","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_lampiran,"name":"radio-siup","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.clear_attachment('siup')}},model:{value:(_vm.fabForm.lampiran_siup),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_siup", $$v)},expression:"fabForm.lampiran_siup"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),(_vm.fabForm.lampiran_siup == 1)?_c('validation-provider',{attrs:{"name":"File SIUP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"mySiup",attrs:{"id":"siup","name":"siup","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingSiupEvent,"vdropzone-complete":_vm.completeSiupEvent,"vdropzone-success":_vm.uploadSiupSuccess,"vdropzone-error":_vm.errorSiupEvent,"vdropzone-removed-file":_vm.removeSiupFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.siup != '') ? _vm.errAttachment.siup: errors[0]))])]}}],null,false,3043160116)}):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"NIB"}},[_c('validation-provider',{attrs:{"name":"NIB","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_lampiran,"name":"radio-tdp","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.clear_attachment('nib')}},model:{value:(_vm.fabForm.lampiran_nib),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_nib", $$v)},expression:"fabForm.lampiran_nib"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),(_vm.fabForm.lampiran_nib == 1)?_c('validation-provider',{attrs:{"name":"File NIB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"myNib",attrs:{"id":"nib","name":"nib","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingNibEvent,"vdropzone-complete":_vm.completeNibEvent,"vdropzone-success":_vm.uploadNibSuccess,"vdropzone-error":_vm.errorNibEvent,"vdropzone-removed-file":_vm.removeNibFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.nib != '') ? _vm.errAttachment.nib: errors[0]))])]}}],null,false,3067367796)}):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Akta Perusahaan"}},[_c('validation-provider',{attrs:{"name":"Akta Perusahaan","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_lampiran,"name":"radio-akta","state":errors.length > 0 ? false : null},on:{"change":function($event){return _vm.clear_attachment('akta')}},model:{value:(_vm.fabForm.lampiran_akta_perusahaan),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_akta_perusahaan", $$v)},expression:"fabForm.lampiran_akta_perusahaan"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])}),_c('br'),(_vm.fabForm.lampiran_akta_perusahaan == 1)?_c('validation-provider',{attrs:{"name":"File Akta Perusahaan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"myAktaPerusahaan",attrs:{"id":"akta_perusahaan","name":"akta_perusahaan","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingAktaPerusahaanEvent,"vdropzone-complete":_vm.completeAktaPerusahaanEvent,"vdropzone-success":_vm.uploadAktaPerusahaanSuccess,"vdropzone-error":_vm.errorAktaPerusahaanEvent,"vdropzone-removed-file":_vm.removeAktaPerusahaanFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.akta_perusahaan != '') ? _vm.errAttachment.akta_perusahaan: errors[0]))])]}}],null,false,2850376788)}):_vm._e()],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"IPL / PBB"}},[_c('b-form-radio-group',{attrs:{"options":_vm.fabForm.deposit == '1' ? _vm.options_lampiran : _vm.options_lampiran_wajib,"name":"radio-ipl-pbb"},on:{"change":function($event){return _vm.clear_attachment('ipl')}},model:{value:(_vm.fabForm.lampiran_ipl_pbb),callback:function ($$v) {_vm.$set(_vm.fabForm, "lampiran_ipl_pbb", $$v)},expression:"fabForm.lampiran_ipl_pbb"}}),_c('br'),(_vm.fabForm.lampiran_ipl_pbb == 1)?_c('validation-provider',{attrs:{"name":"File IPL / PBB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('vue-dropzone',{ref:"myIplPbb",attrs:{"id":"ipl_pbb","name":"ipl_pbb","options":_vm.dropzoneOptions},on:{"vdropzone-sending":_vm.sendingIplPbbEvent,"vdropzone-complete":_vm.completeIplPbbEvent,"vdropzone-success":_vm.uploadIplPbbSuccess,"vdropzone-error":_vm.errorIplPbbEvent,"vdropzone-removed-file":_vm.removeIplPbbFile}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s((_vm.errAttachment.ipl_pbb != '') ? _vm.errAttachment.ipl_pbb: errors[0]))])]}}],null,false,2779872468)}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }