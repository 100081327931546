<template>
  <div v-if="!isLoading">
    <!-- <b-col cols="12" class="mb-2"
      ><h5 class="mb-0">Informasi Pelanggan</h5></b-col> -->
    <b-row>
    <b-col lg="3" col-md-4>
      <b-form-group label="Customer Name" label-for="name">
        <validation-provider #default="{ errors }" name="Customer Title" rules="required">
          <b-form-select style="width: 20%; float: left" @input="change_title" v-model="fabForm.title" :options="dropdown_customer_title" :state="errors.length > 0 ? false : null"></b-form-select>
        </validation-provider>
        <validation-provider #default="{ errors }" name="Customer Name" rules="required|min:3|regex:^([a-zA-Z0-9.\-\s]+)$">
          <b-form-input style="width: 80%; float: left" id="customer_nama" placeholder="Nama" v-model="fabForm.nama" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Phone Number" label-for="customer_phone">
        <validation-provider #default="{ errors }" name="Phone Number" rules="required|min:9">
          <cleave disabled id="customer_phone" v-model="fabForm.phone" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.phone" placeholder="Phone Number"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Handphone" label-for="customer_hp">
        <validation-provider #default="{ errors }" name="Handphone" rules="required|min:9">
          <cleave id="customer_hp" v-model="fabForm.hp" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.hp" placeholder="Handphone"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Handphone 2" label-for="handphone2">
        <validation-provider #default="{ errors }" name="Handphone 2" rules="min:9">
          <cleave id="handphone2" v-model="fabForm.fax" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.fax" placeholder="Handphone 2"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    </b-row>
    <b-row>
      <b-col lg="3" md="6" v-if="Show_pic_name">
        <b-form-group label="PIC Name" label-for="PIC Name">
          <validation-provider #default="{ errors }" name="PIC Title" rules="">
            <b-form-select style="width: 20%; float: left" v-model="fabForm.pic_title" :options="dropdown_customer_title" :state="errors.length > 0 ? false : null"></b-form-select>
            </validation-provider>
          <validation-provider #default="{ errors }" name="PIC Name" rules="alpha_spaces">
            <b-form-input style="width: 80%; float: left" id="pic_nama" placeholder="Nama" v-model="fabForm.pic_name" :state="errors.length > 0 ? false : null"/>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
    </b-row>
    <b-row>
    <b-col lg="3" md="12">
      <b-form-group label="Option">
        <validation-provider #default="{ errors }" name="Option" rules="required">
          <b-form-radio-group @change="change_ktp_passport" :options="options_ktp_passport" v-model="fabForm.ktp_passport" name="radio-option" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6" v-if="Show_ktp">
      <b-form-group label="ID Number" label-for="id_number">
        <validation-provider #default="{ errors }" name="ID Number" rules="required|min:19">
          <cleave id="id_number" v-model="fabForm.ktp" class="form-control" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.ktp" placeholder="0000-0000-0000-0000"/>
          <small class="text-danger" v-if="errors[0]">The ID Number field must be at least 16 characters</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6" v-if="Show_passport">
      <b-form-group label="Passport" label-for="passport">
        <validation-provider #default="{ errors }" name="Passport" rules="required|min:5">
          <cleave id="passport" v-model="fabForm.passport" class="form-control" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.passport" placeholder="000000000"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="NPWP" label-for="customer_npwp">
        <validation-provider #default="{ errors }" name="NPWP" rules="required|min:21">
          <cleave id="customer_npwp" :disabled="Show_option" v-model="fabForm.npwp" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" :raw="false" :options="options.npwp" placeholder="000.000.000.0-000.000"/>
          <small class="text-danger" v-if="errors[0]">The NPWP field must be at least 16 characters</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Kota Kelahiran" label-for="Kota Kelahiran">
        <validation-provider #default="{ errors }" name="Kota Kelahiran" rules="">
          <b-form-input id="dob_place" placeholder="Kota Kelahiran" v-model="fabForm.dob_place" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Tanggal Lahir" label-for="customer_dob">
        <validation-provider #default="{ errors }" name="Tanggal Lahir" rules="">
          <flat-pickr v-model="fabForm.dob" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid'" placeholder="Tanggal Lahir"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" md="6">
      <b-form-group label="Email" label-for="customer_email">
        <validation-provider #default="{ errors }" name="Email" rules="required|email">
          <b-form-input disabled id="customer_email" placeholder="Email" v-model="fabForm.email" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="3" col-md-4>
      <b-form-group label="Email Secondary" label-for="customer_email_secondary">
        <validation-provider #default="{ errors }" name="Email_secondary" rules="email">
          <tag-input :value="defaultValueTag" @responseTagInput="responseTagInput"></tag-input>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
  </div>
  <div v-else>
    <b-col>
      <loading></loading>
    </b-col>
  </div>
</template>

<script>
import {
  ValidationProvider,
  ValidationObserver,
} from "vee-validate/dist/vee-validate.full";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import TagInput from "@/templates/TagInput.vue";

export default {
  name: "customer-information",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Cleave,
    flatPickr,
    required,
    email,
    ToastificationContent,
    TagInput
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      dropdown_customer_title: [],
      isLoading: false,
      Show_ktp: false,
      Show_pic_name: false,
      Show_option: true,
      // Show_email_secondary: false,
      // Show_notes: false,
      Show_passport: false,
      defaultValueTag: [],
      options_ktp_passport: [
        { text: "KTP", value: "1" },
        { text: "Passport", value: "2" },
      ],
      options: {
        ktp: {
          delimiters: ["-", "-", "-", "-"],
          blocks: [4, 4, 4, 4],
          uppercase: true,
          numericOnly: true,
        },
        passport: {
          blocks: ["9"],
          uppercase: true,
        },
        npwp: {
          delimiters: [".", ".", ".", "-", "."],
          blocks: [3, 3, 3, 1, 3, 3],
          uppercase: true,
          numericOnly: true,
        },
        phone: {
          blocks: ["13"],
          prefix: '+62',
          numericOnly: true
        },
        hp: {
          blocks: ["13"],
          prefix: '0',
          numericOnly: true
        },
        fax: {
          blocks: ["13"],
          numericOnly: true
        },
        zipcode: {
          blocks: ["5"],
          numericOnly: true,
        },
      },
    };
  },
  methods: {
    init: function () {
      this.isLoading = true;
      this.get_customer_title();
    },
    validationFormInformation() {
      return new Promise((resolve, reject) => {
        this.$refs.informasiPribadi.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    responseTagInput: function (a) {
      this.fabForm.email_secondary = a;
    },
    change_ktp_passport: function () {
      if (this.fabForm.ktp_passport == 1) {
        this.Show_ktp = true;
        this.Show_passport = false;
        this.fabForm.passport = null;
      } else {
        this.Show_ktp = false;
        this.Show_passport = true;
        this.fabForm.ktp = null;
      }
    },
    change_title: function () {
      if (this.fabForm.title == 5 || this.fabForm.title == 6 || this.fabForm.title == 7) {
        this.Show_pic_name = true;
        this.Show_option = false;
        this.fabForm.npwp = null;
        // this.Show_email_secondary = true;
        // this.Show_notes = true;
        this.fabForm.pic_name = null;
        // this.fabForm.notes = null;
      } else {
        this.Show_pic_name = false;
        this.Show_option = true;
        this.fabForm.npwp = "0000000000000000";
        // this.Show_email_secondary = false;
        // this.Show_notes = false;
        this.fabForm.pic_name = null;
        // this.fabForm.notes = null;
      }
    },
    get_customer_title: function () {
      axios
        .get("self_registration/dropdown/customer-title")
        .then((response) => {
          this.dropdown_customer_title = response.data.data;
        })
        .finally(() => {
          this.fabForm.npwp = "0000000000000000";
          this.isLoading = false;
        });
    },
  },
  mounted() {
    this.init();
  },
};
</script>

