<template>
  <b-row>
    <b-col lg="4" md="6">
      <b-form-group label="Customer Site">
        <validation-provider #default="{ errors }" name="Customer Site" :rules="requiredValidation">
          <b-form-input disabled id="cust_area" placeholder="Customer Site" v-model="fabForm.site_name" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Customer Group">
        <validation-provider #default="{ errors }" name="Customer Group" :rules="requiredValidation">
          <b-form-input disabled id="cust_group" placeholder="Customer Group" v-model="fabForm.group_name" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Customer Type">
        <validation-provider #default="{ errors }" name="Customer Type" :rules="requiredValidation">
          <b-form-input disabled id="cust_type" placeholder="Customer Type" v-model="fabForm.cust_type_name" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Location">
        <validation-provider #default="{ errors }" name="Location" :rules="requiredValidation">
          <b-form-input disabled id="loc" placeholder="Location" v-model="fabForm.location_name" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Location Area">
        <validation-provider #default="{ errors }" name="Location Area" :rules="requiredValidation">
          <b-form-input disabled id="loc_area" placeholder="Location" v-model="fabForm.location_area_name" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Address">
        <validation-provider #default="{ errors }" name="Address" :rules="requiredValidation">
          <model-select @input="autofill_other_address" :options="dropdown" v-model="fabForm.location_address" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
      <!-- <b-form-group label="Address">
        <validation-provider #default="{ errors }" name="Address" :rules="requiredValidation">
          <b-form-select @input="autofill_other_address" :options="dropdown_location_address" v-model="fabForm.location_address"  :state="errors.length > 0 ? false : null">
          <b-form-select-option :value="null" hidden>-- Please select --</b-form-select-option></b-form-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group> -->
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Tax Address">
        <validation-provider #default="{ errors }" name="Tax Address" :rules="requiredValidation">
          <b-form-textarea rows="3" id="i-facebook" v-model="fabForm.tax_address" placeholder="Alamat" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Invoice Address">
        <validation-provider #default="{ errors }" name="Invoice Address" :rules="requiredValidation">
          <b-form-textarea rows="3" id="i-facebook" v-model="fabForm.invoice_address" placeholder="Alamat" :state="errors.length > 0 ? false : null"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Zipcode" label-for="Zipcode">
        <validation-provider #default="{ errors }" name="Zipcode" rules="required|min:5">
          <cleave id="zipcode" v-model="fabForm.zipcode" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid' " :raw="false" :options="options.zipcode" placeholder="Zipcode"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
        <b-form-group label="Status Lokasi">
          <validation-provider
            #default="{ errors }"
            name="status lokasi"
            rules="required"
          >
            <b-form-radio-group
              :options="options_status_lokasi"
              v-model="fabForm.status_lokasi"
              name="radio-inline2"
              :state="errors.length > 0 ? false : null"
            ></b-form-radio-group>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>
      <b-col lg="4" md="6">
      <b-form-group label="Deposit">
        <validation-provider
          #default="{ errors }"
          name="deposit"
          rules="required"
        >
          <b-form-radio-group
            :options="options_deposit"
            v-model="fabForm.deposit"
            name="radio-inline3"
            :state="errors.length > 0 ? false : null"
          ></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Total Deposit" label-for="total_deposit" v-if="fabForm.deposit=='1'">
        <validation-provider #default="{ errors }" name="Total deposit" rules="required">
          <model-select :options="dropdown_deposit" v-model="fabForm.deposit_amount" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Pelanggan Lama ?">
        <validation-provider
          #default="{ errors }"
          name="returnee"
          rules="required"
        >
          <b-form-radio-group
            :options="options_returnee"
            v-model="fabForm.returnee"
            name="radio-inline4"
            :state="errors.length > 0 ? false : null"
          ></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6" v-if="fabForm.returnee === 1">
      <b-form-group label="Kode pelanggan anda sebelumnya" label-for="returnee_customer_code">
        <validation-provider #default="{ errors }" name="Returnee Customer Code" rules="required|min:10">
          <cleave id="returnee_customer_code" v-model="fabForm.returnee_customer_code" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid' " :raw="false" :options="options.returnee_customer_code" placeholder="Returnee Customer Code"/>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
      <b-col lg="4" md="6" v-for="(options_list, question) in questioner" :key="question" :label=question>
        <validation-provider #default="{ errors }" :name="options_list.name" rules="required"> 
        <b-form-group :key="question" :label=question>
          <div v-if="options_list.type == 1">
            <b-form-radio @input="rerfeshValueEmit" v-model="answer[options_list.id_question]" v-for="(options_detail, options_index) in options_list.options" :key="options_index" :name="options_list.id_question" :value="options_detail.value">{{options_detail.text}}</b-form-radio>
          </div>
          <div v-if="options_list.type == 2">
            <b-form-checkbox-group @input="rerfeshValueEmit" v-model="answer[options_list.id_question]" :options="options_list.options" stacked>
            </b-form-checkbox-group>
          </div>
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
      </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "customer-address",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Cleave,
    flatPickr,
    required,
    email,
    ToastificationContent,
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
    dropdown: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      options_status_lokasi: [
        { text: "Pribadi", value: "1" },
        { text: "Sewa", value: "2" },
      ],
      dropdown_customer_area: [],
      dropdown_customer_group_type: [],
      dropdown_customer_type: [],
      dropdown_location: [],
      dropdown_location_area: [],
      dropdown_location_address: [],
      isLoading: false,
      options: {
        zipcode: {
          blocks: ["5"],
          numericOnly: true,
        },
        returnee_customer_code: {
          blocks: ["10"],
          numericOnly: true,
        },
      },
      options_deposit: [
        { text: "Ya", value: "1" },
        { text: "Tidak", value: "0" },
      ],
      options_returnee: [
        { text: "Ya", value: 1 },
        { text: "Tidak", value: 0 },
      ],
      dropdown_deposit: [],
      questioner: [],
      answer:[],
      show_location: null,
    };
  },
  computed: {
    getValueFromCustomerArea: function() {
      let a  = this.dropdown_customer_area.filter( x => x.value === this.fabForm.code_area);
      let b = '';
      a.forEach((v)=>{ 
        b = v
      })
      return b;
    },
    getValueFromLocationAddress: function() {
      if(this.dropdown.length > 0){
        let a  = this.dropdown.filter( x => x.value === this.fabForm.location_address);
        let b = '';
        a.forEach((v)=>{ 
          b = v
        })
        return b;
      }else{
        return false;
      }
    }
  },
  methods: {
    rerfeshValueEmit: function(){
      this.$emit("refreshQuestioner", this.answer);
    },
    init: function () {
      // this.fabForm.title = 1;
      this.isLoading = true;
      this.show_location = 2
      this.getData();
      this.get_deposit();
      // this.get_location_address();
    },
    getData: function () {
      let response = axios.post("self_registration/questioner/listing", {show_location:this.show_location});
      return response.then((response) => {
        this.questioner = response.data.data;
      }).catch((error) => {
        return [];
      });
    },
    get_deposit: function () {
      axios
        .get("self_registration/dropdown/deposit")
        .then((response) => {
          this.dropdown_deposit = response.data.data;
        })
        .finally(() => {
          this.isLoading = false;
        });
    }, 
    validationFormInformation() {
      return new Promise((resolve, reject) => {
        this.$refs.informasiPribadi.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    autofill_other_address: function() {
      this.fabForm.readyness = this.getValueFromLocationAddress['readyness'];

      var postdata = {
        'code_site' : this.fabForm.code_site, 
        'location_area': this.fabForm.location_area, 
        'location_address': this.fabForm.location_address
      }
      
      if(postdata['code_site'] != null && postdata['location_area'] != null && postdata['location_address'] != null){
        axios.post("self_registration/dropdown/autofill-address",postdata).then((response) => {
          this.fabForm.tax_address = response.data.data;
          this.fabForm.invoice_address = response.data.data;
        });
      }
      this.fabForm.selected_product = null;
     }
    
  },
  mounted() {
    this.init();
  },
};
</script>
