<template>
  <div class="tag-input">
    <div v-for="(tag, index) in tags" :key="tag" class="tag-input__tag">
      <span @click='removeTag(index)'>x</span>
      {{ tag }}
    </div>
    <input type='email' placeholder="Enter email" class='tag-input__text' @keydown.enter='addTag' @keydown.188='addTag' @keydown.space='addTag' @change='addTag'/>
  </div>
</template>
<script>
  export default {
    name: "tag-input",
    props: {
      value: {
        type: [Array, Object],
        default: () => [],
      },
    },
    watch: { 
        value: function(newVal, oldVal) { // watch it
          this.tags = [];
          if (newVal.length > 1) {
            for (let i = 0; i < newVal.length; i++) {
              this.tags.push(newVal[i]);
            }
            this.updateData(this.tags);
          } 
          if (newVal.length == 1) {
            this.tags.push(newVal[0]);
            this.updateData(this.tags);
          }
          if (newVal.length == 0) {
            this.tags = [];
            this.updateData(this.tags);
          }
        }
    },
    data () {
      return {
        tags: []
      }
    },
    methods: {
      init(){
        this.tags = this.value;
      },
      addTag (event) {
        event.preventDefault();
        var val = event.target.value.trim();
        var is_valid = this.ValidateEmail(val);
        if(is_valid == true){
          if (val.length > 0) {
            this.tags.push(val);
            event.target.value = '';
            this.updateData(this.tags);
          }
        }else{
          alert("Please Input Valid email address");
        }
        this.dataValue = this.tags;
      },
      removeTag (index) {
        this.tags.splice(index, 1);
        this.updateData(this.tags);
      },
      ValidateEmail(mail) {
        if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)){
          return (true)
        }else{
          return (false)
        }
      },
      updateData: function (a) {
        this.$emit("responseTagInput", a);
      },
    },
    mounted() {
      this.init();
    },
  }
</script>
<style scoped>
  .tag-input {
    width: 100%;
    border: 1px solid #d4d4d4;
    font-size: 0.9em;
    border-radius: 6px;
    height: 100%;
    box-sizing: border-box;
    padding: 0 10px;
  }

  .tag-input__tag {
    height: 30px;
    float: left;
    margin-right: 10px;
    background-color: #eee;
    margin-top: 10px;
    line-height: 30px;
    padding: 0 5px;
    border-radius: 5px;
  }

  .tag-input__tag > span {
    cursor: pointer;
    opacity: 0.75;
  }

  .tag-input__text {
    border: none;
    outline: none;
    font-size: 0.9em;
    line-height: 50px;
    background: none;
  }
</style>