<template>
  <div>
    <b-row>
      <b-col cols="12" class="mb-2">
        <h5 class="mb-0">Layanan Berlangganan</h5>
      </b-col>
      <b-col md="6">
        <b-form-group label-for="i-facebook" label="Pilih Layanan">
          <validation-provider
            #default="{ errors }"
            name="Product Berlangganan"
            rules="required"
          >
            <multiselect
              v-model="fabForm.selected_product"
              @select="showSubscribe"
              @remove="removeSubscribe"
              :options="products"
              :multiple="true"
              :close-on-select="true"
              :clear-on-select="true"
              placeholder="Pick some"
              label="text"
              track-by="value"
            ></multiselect>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
      </b-col>

      <b-col md="12" v-if="fabForm.selected_product">
        <br />
        <b-card no-body>
          <b-card-header>
            <b-card-title> Your Subscription </b-card-title>
          </b-card-header>
          <b-card-body>
            <div v-for="item2 in fabForm.products_customer_view" >
              <div v-for="(item, index) in item2" v-bind:key="item.text + item.price_catalog_detail_code">
              <dl class="row" v-if="index == 0">
                <dt class="col-sm-6">
                  <div style="background-color: #f2f2f2;">
                    {{ item.product_category_name }}  
                  </div>  
                </dt>
              </dl>
              <dl class="row">
                <dt class="col-sm-3">
                  {{ item.text }} 
                  <div v-if="item.value === 'PR22051800002'">
                    <small v-if="item.multiple_qty === '1'">(x{{ fabForm.multiple[item.value] }} Line Telephone)</small>
                  </div>
                  <div v-if="item.value !== 'PR22051800002'">
                    <small v-if="item.multiple_qty === '1'">(x{{ fabForm.multiple[item.value] }} {{item.text}})</small>
                  </div>
                </dt>
                
                <dd class="col-sm-9" v-if="item.multiple_qty !== '1'">
                  <strike v-if="item.promotion_name !== null">Rp. {{ formatPrice(item.price_exclude_tax) }}</strike>
                  <div v-else>Rp. {{ formatPrice(item.price_exclude_tax) }}</div>
                </dd>
              
                <dd class="col-sm-9" v-if="item.multiple_qty === '1'">
                  <strike v-if="item.promotion_name !== null">Rp. {{ formatPrice(fabForm.multiple[item.value] * item.price_exclude_tax) }}</strike>
                  <div v-else>Rp. {{ formatPrice(fabForm.multiple[item.value] * item.price_exclude_tax) }}</div>
                </dd>

                <dd class="col-sm-9" v-if="item.product_category === '5'">
                  <div>Rp. {{ formatPrice(item.price_exclude_tax) }} &nbsp; <feather-icon icon="EditIcon" class="mr-40" style="margin-bottom:5px;cursor:hand;" @click="updateDedicatedPrice(item)"/>
                  </div>
                </dd>
              </dl>

              <dl class="row" v-if="item.promotion_name !== null">
                <dt class="col-sm-3">
                  -
                </dt>
                <dd class="col-sm-9" v-if="item.product_category !== '1'">
                  Rp. {{ formatPrice(item.total_calculation) }} ({{item.promotion_name}})  
                  <feather-icon style="cursor:hand; color:black;" title="Remove Promotion" icon="TrashIcon" class="mr-50" @click="remove_promotion(key)" />
                </dd>
                <dd class="col-sm-9" v-if="item.product_category === '1'">
                  Rp. {{ formatPrice(fabForm.multiple[item.value] * item.total_calculation) }} ({{item.promotion_name}})
                </dd>
              </dl>
              </div>
            </div>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <b-modal ref="modalForm" id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="md">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">Update Price</h3>
      </div>

      <validation-observer v-if="!isLoading" ref="customerAddressType" tag="form" @submit.prevent="updateProductPrice">
        <b-form>
          <b-form-group label="Price">
            <validation-provider #default="{ errors }" name="Price" rules="required">
              <b-form-input type="number" id="Price" placeholder="Price" v-model="updatePrice" :state="errors.length > 0 ? false : null"/>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
      </validation-observer>
      <loading v-if="isLoading"></loading>

      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="danger" @click="close_modal"><i class="fa fa-undo"></i> Back</b-button>
        &nbsp;
        <b-button type="button" size="sm" variant="primary" @click="updateProductPrice"><i class="fa fa-save"></i> Save</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from "vue-multiselect";
import SubscriptionTable from "@/templates/SubscriptionTable.vue";

export default {
  name: "customer-address",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Multiselect,
    required,
    ToastificationContent,
    SubscriptionTable,
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
    products: {
      type: [Array, Object],
      default: () => [],
    },
  },
  data() {
    return {
      options_lokasi_berlangganan: [
        { text: "Sesuai Poin A", value: "1" },
        { text: "Lainnya", value: "0" },
      ],
      updatePrice: 0,
      updatePriceKey: null,
      undoTemp: [],
      isLoading: false,
      reload: false,
      fields: [
        { key: "text", label: "Subsription" },
        { key: "price", label: "Price" },
      ],
      lineTelephone: 0,
      subs: [],
      temps: {},
      totals: 0,
      products_customer_view: []
    };
  },
  computed: {
    total(e) {
      let temp = 0;
      return (temp += e);
    },
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
    },
    updateDedicatedPrice: function(key){
      var xyz = this.fabForm.selected_product.indexOf(key);
      this.updatePrice = this.fabForm.selected_product[xyz].price;
      this.updatePriceKey = xyz;
      this.$refs.modalForm.show();
    },
    remove_promotion: function(key){
      if (confirm("Are you sure want to Remove this Promotion ?")) {
        this.fabForm.selected_product[key]['total_calculation'] = null;
        this.fabForm.selected_product[key]['discount_price']    = null;
        this.fabForm.selected_product[key]['promotion_status']  = null;
        this.fabForm.selected_product[key]['promotion_name']    = null;
        this.fabForm.selected_product[key]['promotion_code']    = null;
      }
      this.$emit("refreshDopdown",{area: this.fabForm.code_area,group:this.fabForm.code_group,type:this.fabForm.code_type,location:this.fabForm.location});
    },
    close_modal: function(){
      this.$refs.modalForm.hide();
    },
    updateProductPrice: function(){
      var cba = this.fabForm.selected_product[this.updatePriceKey].product_category;
      var zxy = this.fabForm.products_customer_view[cba].indexOf(this.fabForm.selected_product[this.updatePriceKey]);
      this.fabForm.products_customer_view[cba][zxy].price = this.updatePrice;
      this.fabForm.selected_product[this.updatePriceKey].price = this.updatePrice;
      // console.log(this.products_customer_view[cba][zxy]);
      this.$refs.modalForm.hide();
    },
    removeSubscribe: function (e) {
      
      if(this.fabForm.products_customer_view[e.product_category] != undefined) {
        var abc = this.fabForm.products_customer_view[e.product_category].indexOf(e);
        this.fabForm.products_customer_view[e.product_category].splice(abc, 1);
        // this.fabForm.products_customer_view = this.products_customer_view;
      }
      if(e.multiple_qty === '1') {
        this.$delete(this.temps, e.value);
        this.fabForm.multiple = this.temps;
      }
    },
    showSubscribe: function (e) {  
      
      if(this.fabForm.products_customer_view[e.product_category] == undefined) {
        this.fabForm.products_customer_view[e.product_category] = [];
        this.fabForm.products_customer_view[e.product_category].push(e);
      } else {
        this.fabForm.products_customer_view[e.product_category].push(e);
      }
      // this.fabForm.products_customer_view = this.products_customer_view;
      
      // console.log(this.fabForm.products_customer_view);

      if (e.multiple_qty === '1') {
        var temp = prompt("Masukkan jumlah qty anda", 1);
        if (isNaN(temp)) {
          // NaN means Not a Number check if it is
          //this.lineTelephone = 1;
          this.temps[e.value] = 1;
          alert("Qty Minimal 1");
        } else {
          if (temp < 1) {
            this.temps[e.value] = 1;
            alert("Minimal 1 line telephone");
          } else {
            //this.lineTelephone = temp;
            //this.fabForm.lineTelephone = temp;
            //this.fabForm.multiple[e.value] = 1;
            this.temps[e.value] = temp;       
           }
        }
         this.fabForm.multiple = this.temps;
       
      } 
    },
    formatPrice(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
