<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Informasi pemasangan</h5>
    </b-col>
    <b-col md="6">
      <b-form-group label="Waktu Pemasangan Aktivasi" label-for="waktupasang">
        <validation-provider
          #default="{ errors }"
          name="Waktu pemasangan"
          :rules="requiredValidation"
        >
          <model-select @input="cek_available_time" :options="dropdownTimeInstallation" v-model="fabForm.periode_time_installation" placeholder="-- Please select --" :state="errors.length > 0 ? false : null"></model-select>
          <!--b-form-datepicker id="tanggalaktiv" v-model="fabForm.tanggal_aktivasi" class="mb-2" :state="errors.length > 0 ? false : null"></b-form-datepicker-->
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
    </b-col>
    <b-col md="6">
      <b-form-group label="Tanggal Pemasangan" label-for="tanggalpasang">
        <validation-provider
          #default="{ errors }"
          name="Tanggal pemasangan"
          :rules="requiredValidation"
        >
          <flat-pickr
            :config="config"
            v-model="fabForm.installation_date"
            :class="
              errors.length === 0 ? 'form-control' : 'form-control is-invalid'
            "
            placeholder="Tanggal Pemasangan"
            :onChange="getActivationDate()"
          />
          <!--b-form-datepicker id="tanggalpasang" v-model="fabForm.tanggal_pemasangan" class="mb-2" :state="errors.length > 0 ? false : null"></b-form-datepicker-->
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col md="6">
      <b-form-group label="Tanggal Aktivasi" label-for="tanggalaktiv">
        <validation-provider
          #default="{ errors }"
          name="Tanggal aktivasi"
        >
          <b-form-input id="tanggalaktiv" placeholder="Tanggal Aktivasi" v-model="fabForm.activation_date" readonly />
          <!-- <flat-pickr
            :config="config"
            v-model="fabForm.activation_date"
            :class="
              errors.length === 0 ? 'form-control' : 'form-control is-invalid'
            "
            placeholder="Tanggal Aktivasi"
          /> -->
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import flatPickr from "vue-flatpickr-component";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "customer-information",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    required,
    flatPickr,
    ToastificationContent,
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
    dropdownTimeInstallation: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      returnDisabled: [],
    };
  },
  computed: {
    config() {
      let minDate = new Date();
      if (this.fabForm.readyness !== null) {
        minDate.setDate(minDate.getDate() + parseInt(this.fabForm.readyness));
      } else {
        minDate.setDate(minDate.getDate() + 7);
      }

      // Array tanggal yang ingin diaktifkan (yang tersedia)
      const availableDatesList = this.returnDisabled;

      // Konversi tanggal yang tersedia ke format yang sesuai
      const availableDates = availableDatesList.map(dateStr => {
        const date = new Date(dateStr);
        date.setHours(0, 0, 0, 0); // Set the time to midnight to avoid timezone issues
        return date.toISOString().split('T')[0];
      });

      return {
        minDate: minDate,
        disable: [
          date => {
            let checkDate = new Date(date);
            checkDate.setHours(0, 0, 0, 0);
            const checkDateString = checkDate.toISOString().split('T')[0];
            return !availableDates.includes(checkDateString);
          }
        ]
      };
    }
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
      this.isLoading = true;
    },
    getActivationDate: function () {
      this.fabForm.activation_date = this.fabForm.installation_date;
    },
    cek_available_time: function(){
      this.fabForm.installation_date = null;
      this.fabForm.activation_date = null;
      // this.fabForm.code_site = 'CS0001';
      // console.log(this.fabForm.periode_time_installation);

      axios.get("check/cek_period_time/"+this.fabForm.code_site+"/"+this.fabForm.readyness+"/"+this.fabForm.periode_time_installation).then((response) => {
        this.returnDisabled = response.data.available_date;
      }).finally(() => {
        this.isLoading = false;
      });
    }
  },
  mounted() {
    this.init();
  },
};
</script>
