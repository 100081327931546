<template>
  <div>
    <b-alert variant="primary" show>
      <div class="alert-body">
        <h2><span style="color:red;">Rekomendasi :</span> <span style="color:blue;">{{questioner_result_suggestion.results}}</span></h2>
      </div>
    </b-alert>
    <br>
    <b-tabs vertical content-class="col-12 col-md-8 col-lg-9" pills nav-wrapper-class="faq-navigation col-md-4 col-lg-3 col-12" nav-class="nav-left">
      <b-tab v-for="(category, index_products) in productsNew" v-bind:key="index_products">
        
        <template #title>
          <feather-icon :icon="category.icon" size="18" class="mr-1" />
          <span class="font-weight-bold">{{ index_products }}</span>
        </template>
        <b-row>
          <b-col v-for="(card_list, index_category) in category.data" md="3" sm="12" v-bind:key="index_category" class="swiper-slide">
            <b-card>
              <h3 style="margin-bottom: 30px; height: 40px">{{card_list.text}}</h3>

              <div class="annual-plan">
                <div class="plan-price mt-2" style="font-size:13px;margin-bottom:-20px;" v-if="card_list.promotion_code != null">
                  <strike>IDR</strike>
                  <span class="pricing-basic-value font-weight-bolder" ><strike> {{formatPrice(card_list.price_exclude_tax)}}</strike></span>
                  <sub class="pricing-duration text-body font-weight-bold">/month</sub >
                </div>
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="card_list.promotion_code == null">{{formatPrice(card_list.price_exclude_tax)}}</span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="card_list.promotion_code != null">{{formatPrice(card_list.total_calculation)}}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub >
                </div>
              </div>
              
              <b-list-group class="list-group-circle text-left">
                <template>
                  <!-- {{card_list.installation_array_index}} -->
                  <b-list-group-item v-if="card_list.installation_product_price != null">
                    Installation Fee
                    <br />
                    <small class="annual-pricing text-muted" >IDR {{formatPrice(card_list.installation_product_price)}} (One Time)</small>
                  </b-list-group-item>
                </template>
                <b-list-group-item >
                  Up To {{card_list.text}}
                </b-list-group-item>
              </b-list-group>

              <b-button block class="mt-2" variant="primary" @click="selectProduct(card_list)">Select</b-button>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <div class="floating-button-cart" @click="showCart">
        <b-badge pill variant="primary">{{cart.length}}</b-badge>
        <feather-icon icon="ShoppingCartIcon" size="20" color="black"/>
      </div>
    </b-tabs>

    <b-modal ref="modalForm" id="addModalAddress" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="xl">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">My Subscription</h3>
      </div>

        <!-- <pre>{{fabForm.selectProduct}}</pre> -->
        <b-row>
          <b-col md="4" sm="12" v-for="(list, index_cart) in cart" v-bind:key="index_cart">
            <b-card>
              <h3 style="margin-bottom: 30px; height: 40px">{{list.text}}</h3>

              <div class="annual-plan">
                <div class="plan-price mt-2" style="font-size:13px;margin-bottom:-20px;" v-if="list.promotion_code != null">
                  <strike>IDR</strike>
                  <span class="pricing-basic-value font-weight-bolder" ><strike> {{formatPrice(list.price_exclude_tax)}}</strike></span>
                  <sub class="pricing-duration text-body font-weight-bold">/month</sub >
                </div>
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="list.promotion_code == null">{{formatPrice(list.price_exclude_tax)}}</span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="list.promotion_code != null">{{formatPrice(list.total_calculation)}}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub >
                </div>
              </div>
              
              <b-list-group class="list-group-circle text-left">
                <template>
                  <b-list-group-item v-if="list.multiple_qty == '1'">
                    Qty <br/><br/> 
                    <cleave  style="width:5em" id="quantity" :options="options.quantity" :onchange="getQty(list.item_quantity,index_cart,list.value)" v-model="list.item_quantity" class="form-control" placeholder="Quantity"/>
                  </b-list-group-item>
                  <b-list-group-item v-else>
                    Qty <br/><br/> 
                    <b-form-input disabled style="width:5em" id="quantity" v-model="list.item_quantity" class="form-control" placeholder="Quantity"/>
                  </b-list-group-item>
                </template>                
              </b-list-group>

              <b-list-group class="list-group-circle text-left">
                <template>
                  <b-list-group-item v-if="list.installation_product_price != null">
                    Installation Fee
                    <br />
                    <small class="annual-pricing text-muted" >IDR {{formatPrice(list.installation_product_price)}} (One Time)</small>
                  </b-list-group-item>
                </template>
                <b-list-group-item >
                  Up To {{list.text}}
                </b-list-group-item>
              </b-list-group>

              <b-button block class="mt-2" variant="danger" @click="removeCart(index_cart)">Remove</b-button>
            </b-card>
          </b-col>
        </b-row>

      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="primary" @click="closeCart"><i class="fa fa-undo"></i> Close</b-button>
      </div>
    </b-modal>
  </div>
</template>
<style>

.floating-button-cart {
  float:right;
  position: inline;
  z-index: 1031;
  margin-right: 15px;
  background-color: #9AC4EF;
  padding: 15px;
  border-radius: 90px;
  cursor: pointer;
}

</style>
<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import Cleave from "vue-cleave-component";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from "vue-multiselect";
import SubscriptionTable from "@/templates/SubscriptionTable.vue";
// import { faListAlt } from '@fortawesome/free-solid-svg-icons';

export default {
  name: "customer-address",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Cleave,
    Multiselect,
    required,
    ToastificationContent,
    SubscriptionTable,
  },
  props: {
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
    productsNew: {
      type: [Array, Object],
      default: () => [],
    },
    questioner_result_suggestion: {
      type: [Array, Object],
      default: () => {},
    }
  },
  data() {
    return {
      cart: [],
      listing_product : [],
      options_lokasi_berlangganan: [
        { text: "Sesuai Poin A", value: "1" },
        { text: "Lainnya", value: "0" },
      ],
      options: {
        quantity: {
          blocks: ["4"],
          numericOnly: true,
        },
      },
      updatePrice: 0,
      updatePriceKey: null,
      undoTemp: [],
      isLoading: false,
      reload: false,
      fields: [
        { key: "text", label: "Subsription" },
        { key: "price", label: "Price" },
      ],
      lineTelephone: 0,
      subs: [],
      temps: {},
      totals: 0,
      products_customer_view: []
    };
  },
  computed: {
    total(e) {
      let temp = 0;
      return (temp += e);
    },
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
      this.fabForm.products_customer_view = [];
      this.cart = [];

      // document.getElementsByClassName('wizard-footer-right')[0].style.visibility="hidden";
    },
    getQty: function (e,i,p) {
      if (e == "" || e == 0) {
        this.cart[i].item_quantity = 1;
        alert("Qty Minimal 1");
      }
    },
    formatPrice(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    selectProduct: function(list){
      
      var check = true;
      this.cart.forEach( item => {
        if(item.value == list.value){
          check = false;
        }else{
          check = true;
        }
      });

      if(check == true){
        if (list.multiple_qty === '1') {
          var temp = prompt("Masukkan jumlah qty anda", 1);
          if(temp != null) {
            if (isNaN(temp)) {
              // NaN means Not a Number check if it is
              this.temps[list.value] = 1;
              alert("Qty Minimal 1");
            } else {
              if (temp < 1) {
                this.temps[list.value] = 1;
                alert("Minimal 1 line telephone");
              } else {
                this.temps[list.value] = temp; 
              }              
            }
            this.fabForm.multiple = this.temps;
            list.item_quantity = this.temps[list.value]; 
            this.cart.push(list);        
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Product Added!",
                icon: "CheckIcon",
                variant: "success",
                text: 'Product Successfully added to Cart',
              },
            });   
          }                     
        } else {
          this.cart.push(list);        
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Product Added!",
              icon: "CheckIcon",
              variant: "success",
              text: 'Product Successfully added to Cart',
            },
          });
        }        
      }else{
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Product Already Exist!",
            icon: "EditIcon",
            variant: "danger",
            text: 'Product Successfully added to Cart',
          },
        });
      }
      this.refresh_array_variable();
    },
    showCart: function(){
      if(this.cart.length == 0){
        alert("Please Select Product first!");
      }else{
        this.$refs.modalForm.show();
      }
    },
    closeCart: function(){
      this.$refs.modalForm.hide();
    },
    removeCart: function(index){
      if (confirm("Are you sure want to Remove this item ?")) {
        this.cart.splice(index, 1); 
        this.fabForm.selected_product = this.cart;
        if(this.cart.length == 0){
          this.$refs.modalForm.hide();
        }

        this.refresh_array_variable();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Remove Product Success",
            icon: "EditIcon",
            variant: "success",
            text: 'Product Successfully Removed',
          },
        });
      }
    },
    refresh_array_variable: function(){
      this.fabForm.products_customer_view = this.cart;
      this.$emit("finalizeArray", this.cart);
    }
  },
  mounted() {
    this.init();
  },
};
</script>
