<template>
  <b-row v-if="isLoadingPreview == false && isLoadingDownload == false" class="envelope">
    <b-col lg="12">
      <b-card-header style="text-align: center">
        <h3>Customer Information</h3>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col lg="3" sm="6">
            <dt><strong>Nama</strong></dt>
            <dd>{{ fabForm.customer_title }} {{ fabForm.nama }}</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="fabForm.pic_name !=null">
            <dt><strong>Pic Nama</strong></dt>
            <dd> {{ fabForm.pic_name }}</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="this.fabForm.ktp !=null">
            <dt><strong>KTP</strong></dt>
            <dd>{{ fabForm.ktp }}</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="this.fabForm.passport !=null">
            <dt><strong>Passport</strong></dt>
            <dd>{{ fabForm.passport }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>NPWP</strong></dt>
            <dd>{{ fabForm.npwp }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Email</strong></dt>
            <dd>{{ fabForm.email }}</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="this.fabForm.email_secondary !=null">
            <dt><strong>Email Secondary</strong></dt>
            <dd>{{ fabForm.email_secondary.toString() }}</dd>
          </b-col>
        </b-row>
        <b-row>
          <!--b-col lg="3" sm="6">
              <dt><strong>Phone Number</strong></dt>
              <dd>{{ fabForm.phone }}</dd>
            </b-col-->
          <b-col lg="3" sm="6">
            <dt><strong>Handphone 1</strong></dt>
            <dd>{{ fabForm.hp }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Handphone 2</strong></dt>
            <dd>{{ fabForm.fax }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>ZipCode</strong></dt>
            <dd>{{ fabForm.zipcode }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Tanggal Lahir</strong></dt>
            <dd>{{ fabForm.dob }}</dd>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" sm="6">
            <dt><strong>Customer Site</strong></dt>
            <dd>{{ dataPreview.customer_site }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Customer Group</strong></dt>
            <dd>{{ dataPreview.customer_group }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Customer Type</strong></dt>
            <dd>{{ dataPreview.customer_type }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Location</strong></dt>
            <dd>
              {{ dataPreview.location }} ({{ dataPreview.status_lokasi }})
            </dd>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" sm="6">
            <dt><strong>Location Area</strong></dt>
            <dd>{{ dataPreview.location_area }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Location Address</strong></dt>
            <dd>{{ dataPreview.location_address }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Tax Address</strong></dt>
            <dd>{{ fabForm.tax_address }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Invoice Address</strong></dt>
            <dd>{{ fabForm.invoice_address }}</dd>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col lg="3" sm="6">
            <dt><strong>Jenis Pembayaran</strong></dt>
            <dd>{{ dataPreview.jenis_pembayaran }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Periode</strong></dt>
            <dd>{{ dataPreview.periode_berlangganan }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Pemasangan</strong></dt>
            <dd>{{ fabForm.installation_date }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Aktivasi</strong></dt>
            <dd>{{ fabForm.activation_date }}</dd>
          </b-col>
        </b-row>
        <b-row>
          <b-col lg="3" sm="6">
            <dt><strong>KTP / KITAS</strong></dt>
            <dd>
              <a v-if="dataPreview.file_ktp" href="#" @click="download_file(dataPreview.file_ktp)" >View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>NPWP</strong></dt>
            <dd>
              <a v-if="dataPreview.file_npwp" href="#" @click="download_file(dataPreview.file_npwp)">View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Akta Perusahaan</strong></dt>
            <dd>
              <a v-if="dataPreview.file_akta_perusahaan" href="#" @click="download_file(dataPreview.file_akta_perusahaan)">View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>SIUP</strong></dt>
            <dd>
              <a v-if="dataPreview.file_siup" href="#" @click="download_file(dataPreview.file_siup)">View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>NIB</strong></dt>
            <dd>
              <a v-if="dataPreview.file_nib" href="#" @click="download_file(dataPreview.file_nib)">View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>IPL</strong></dt>
            <dd>
              <a v-if="dataPreview.file_ipl" href="#" @click="download_file(dataPreview.file_ipl)">View</a>
              <a v-else href="#">No File</a>
            </dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Deposit</strong></dt>
            <dd v-if="fabForm.deposit == '1'">Ya</dd>
            <dd v-if="fabForm.deposit == '0'">Tidak</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="fabForm.deposit == '1'">
            <dt><strong>Total Deposit</strong></dt>
            <dd>Rp. {{ formatPrice(fabForm.deposit_amount) }}</dd>
          </b-col>
          <b-col lg="3" sm="6">
            <dt><strong>Pelanggan Lama ?</strong></dt>
            <dd v-if="fabForm.returnee === 1">Ya</dd>
            <dd v-if="fabForm.returnee === 0">Tidak</dd>
          </b-col>
          <b-col lg="3" sm="6" v-if="fabForm.returnee === 1">
            <dt><strong>Kode pelanggan anda sebelumnya</strong></dt>
            <dd>{{ fabForm.returnee_customer_code }}</dd>
          </b-col>
        </b-row>
      </b-card-body>
    </b-col>
    <b-col lg="12">
      <br />
      <b-card-title style="text-align: center">
        <h3>My Subscription</h3>
      </b-card-title>
      <b-card-body>
        <b-row>
          <b-col md="4" sm="12" v-for="(list, index_cart) in fabForm.products_customer_view" v-bind:key="index_cart">
            <b-card>
              <h3 style="margin-bottom: 30px; height: 40px">{{list.text}}</h3>

              <div class="annual-plan">
                <div class="plan-price mt-2" style="font-size:13px;margin-bottom:-20px;" v-if="list.promotion_code != null">
                  <strike>IDR</strike>
                  <span class="pricing-basic-value font-weight-bolder" ><strike> {{formatPrice(list.price_exclude_tax)}}</strike></span>
                  <sub class="pricing-duration text-body font-weight-bold">/month</sub >
                </div>
                <div class="plan-price mt-2">
                  <sup class="font-medium-1 font-weight-bold text-primary">IDR</sup>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="list.promotion_code == null">{{formatPrice(list.price_exclude_tax)}}</span>
                  <span class="pricing-basic-value font-weight-bolder text-primary" style="font-size: 2rem" v-if="list.promotion_code != null">{{formatPrice(list.total_calculation)}}</span>
                  <sub class="pricing-duration text-body font-medium-1 font-weight-bold">/month</sub >
                </div>
              </div>
              
              <b-list-group class="list-group-circle text-left">
                <template>
                  <b-list-group-item>
                    Qty : {{ list.item_quantity }}
                  </b-list-group-item>
                </template>                
              </b-list-group>

              <b-list-group class="list-group-circle text-left">
                <template>
                  <b-list-group-item v-if="list.installation_product_price != null">
                    Installation Fee
                    <br />
                    <small class="annual-pricing text-muted" >IDR {{formatPrice(list.installation_product_price)}} (One Time)</small>
                  </b-list-group-item>
                </template>
                <b-list-group-item >
                  Up To {{list.text}}
                </b-list-group-item>
              </b-list-group>

            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
      
    </b-col>
    <b-col lg="12">
      <br />
      <b-card-title style="text-align: center">
        <h3>Authentication</h3>
      </b-card-title>
      <b-card-body>
        <div style="text-align: center">
          <p>Please sign here</p>
          <b-form-radio-group :options="options_signature" @change="signature_option()" v-model="option_signature" name="radio-signature"></b-form-radio-group>
          <br />
          <VueSignaturePad v-if="!showUpload" class="signature" width="300px" height="250px" ref="signaturePad" :options="{ onBegin, onEnd }"/>
          <br />
          <div>
            <!--b-button type="button" size="sm" variant="danger" @click="tnc"><i class="fa fa-undo"></i> Terms & Condition</b-button-->
            <b-button v-if="!showUpload" type="button" size="sm" variant="danger" @click="clearSignature"><i class="fa fa-undo"></i> Clear</b-button>
            <b-form-group v-if="showUpload" label="Signature" style="margin: auto; display: block; width: 300px">
              <validation-provider #default="{ errors }" name="Signature" rules="required">
              <vue-dropzone ref="Signature" id="signature" name="signature"
                v-on:vdropzone-sending="sendingSignatureEvent"
                v-on:vdropzone-complete="completeSignatureEvent" 
                v-on:vdropzone-success="uploadSignatureSuccess"
                v-on:vdropzone-error="errorSignatureEvent" 
                v-on:vdropzone-removed-file="removeSignatureFile" 
                :options="dropzoneOptions"></vue-dropzone>
                <small class="text-danger">{{ (errAttachment.signature != '') ? errAttachment.signature: errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            <br />
            <br />
            <b-form-group label="Selfie Photo" style="margin: auto; display: block; width: 300px">
              <validation-provider #default="{ errors }" name="Selfie" rules="required">
              <vue-dropzone ref="Selfie" id="selfie" name="selfie"
                v-on:vdropzone-sending="sendingSelfieEvent"
                v-on:vdropzone-complete="completeSelfieEvent" 
                v-on:vdropzone-success="uploadSelfieSuccess"
                v-on:vdropzone-error="errorSelfieEvent" 
                v-on:vdropzone-removed-file="removeSelfieFile" 
                :options="dropzoneOptions"></vue-dropzone>
                <!-- <b-form-file :state="errors.length > 0 ? false : null" v-model="fabForm.selfie" ref="file-input_selfie" class="mb-2" required></b-form-file> -->
                <small class="text-danger">{{ (errAttachment.selfie != '') ? errAttachment.selfie: errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            
            <b-form-group label="Notes" style="margin: auto; display: block; width: 600px">
              <validation-provider #default="{ errors }" name="notes">
                <b-textarea id="notes" v-model="fabForm.notes" :class="errors.length === 0 ? 'form-control' : 'form-control is-invalid' " placeholder="Notes" rows="2"></b-textarea>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
            
          </div>
        </div>
      </b-card-body>
      <b-card-footer>
        <div v-if="showTnC" @click="tnc">
          <b-form-checkbox style="display: block; float: right" id="checkbox-1" v-model="fabForm.terms" name="checkbox-1" value="accepted" unchecked-value="not_accepted" >I accept the terms and use</b-form-checkbox>
        </div>
      </b-card-footer>
    </b-col>

    <b-modal ref="modalForm" scrollable id="addModals" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="xl">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">Terms & condition</h3>
      </div>
      <b-row>
        <b-col v-if="!loadingTnc" md="12" sm="12" lg="6" class="ql-indent-1"><div v-html="tnc_bahasa"></div></b-col>
        <b-col v-if="!loadingTnc" md="12" sm="12" lg="6" class="ql-indent-2" v-html="tnc_english"></b-col>
        <b-col v-if="loadingTnc" md="12" sm="12" lg="12"><loading></loading></b-col>
      </b-row>
      <div slot="modal-footer" class="float-right">
        <b-button type="button" size="sm" variant="danger" @click="disaggreeTerms" ><i class="fa fa-undo"></i> Disagree</b-button>
        &nbsp;
        <b-button type="button" size="sm" variant="primary" @click="aggreeTerms"><i class="fa fa-save"></i> Aggree</b-button>
      </div>
    </b-modal>

    <b-modal ref="previewModal" id="previewModalAttachment" header-border-variant="no-border text-center" footer-border-variant="no-border" footer-bg-variant="light" :no-close-on-backdrop="true" :no-close-on-esc="true" size="lg">
      <div slot="modal-header" class="text-center">
        <h3 class="text-center">
          Preview
        </h3>
      </div>
        <b-form style="text-align:center;">
          <b-form-group>
              <img class="img" :src="img_preview" style="width:600px">
          </b-form-group>
        </b-form>
      <div slot="modal-footer" class="float-right">
        <b-button
          type="button"
          size="sm"
          variant="danger"
          @click="closePreview"
          ><i class="fa fa-undo"></i> Back</b-button
        >
      </div>
    </b-modal>
  </b-row>
  <b-row v-else>
    <b-col>
      <loading></loading>
    </b-col>
  </b-row>
</template>

<style>
.signature {
  margin: 0 auto;
}
canvas {
  border: 1px solid rgba(255, 0, 0, 0.5);
  border-radius: 5px;
  border-color: black;
  background-color: white;
}
.centerform {
  background-size: cover;
  color: white;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
}
.envelope {
  padding: 1em;
  border: 1em solid transparent;
  background: linear-gradient(white, white) padding-box,
    repeating-linear-gradient(
        -45deg,
        #f69ec4 0,
        #f69ec4 12.5%,
        transparent 0,
        transparent 25%,
        #7eb4e2 0,
        #7eb4e2 37.5%,
        transparent 0,
        transparent 50%
      )
      0 / 5em 5em;
}
.ql-indent-1 {
  padding-left: 3em;
}
.ql-indent-2 {
  padding-left: 3em;
}
</style>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
import Cleave from "vue-cleave-component";
import flatPickr from "vue-flatpickr-component";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import GlobalTable from "@/templates/GlobalTable.vue";
import vue2Dropzone from 'vue2-dropzone';

export default {
  name: "customer-information",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Cleave,
    flatPickr,
    required,
    email,
    ToastificationContent,
    GlobalTable,
    vueDropzone: vue2Dropzone
  },
  props: {
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
    dataPreview: {
      type: [Array, Object],
      default: () => {},
    },
    isLoadingPreview: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoadingDownload: false,
      fields: [
        { key: "text", label: "Name" },
        { key: "price", label: "Price" },
      ],
      errAttachment: {
        selfie: null,
      },
      dropzoneOptions: {
          url: axios.defaults.baseURL+'upload/add-assets',
          //url: 'https://apiefab.netciti.co.id/netciti-api/public/tools/upload',
          //url: 'http://10.3.0.231/netciti-api/public/tools/upload',
          thumbnailWidth: 150,
          maxFilesize: 5,
          paramName:"attachment",
          disablePreviews: true,
          maxFiles: 1,
          addRemoveLinks: true,
          acceptedFiles: "image/*,application/pdf",
          headers: { 
              'Cache-Control': '',
          }
      },
      showTnC: false,
      loadingTnc: false,
      // Show_ktp: false,
      // Show_passport: false,
      dropdown_customer_title: [],
      dropdown_customer_area: [],
      dropdown_customer_group: [],
      dropdown_customer_type: [],
      dropdown_location: [],
      dropdown_location_area: [],
      dropdown_location_address: [],
      options_status_lokasi: [
        { text: "Pribadi", value: "2" },
        { text: "Sewa", value: "1" },
      ],
      options: {
        ktp: {
          delimiters: ["-", "-", "-", "-"],
          blocks: [4, 4, 4, 4],
          uppercase: true,
        },
        npwp: {
          delimiters: [".", ".", ".", "-", "."],
          blocks: [2, 3, 3, 1, 3, 3],
          uppercase: true,
        },
      },
      tnc_bahasa: "",
      tnc_english: "",
      img_preview: null,
      options_signature: [
        { text: "Sign Manual", value: "1" },
        { text: "Upload", value: "2" },
      ],
      showUpload: false,
      option_signature: "1"
    };
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
      this.captured = false;
      this.camOn = false;
      this.fabForm.option_signature = this.option_signature;
      // if (this.fabForm.ktp_passport == 1) {
      //   this.Show_ktp = true;
      //   this.Show_passport = false;
      // } else {
      //   this.Show_passport = true;
      //   this.Show_ktp = false;
      // }
    },
    onBegin() {
      this.$refs.signaturePad.resizeCanvas();
    },
    onEnd() {
      const { isEmpty, data } = this.$refs.signaturePad.saveSignature();
      this.fabForm.signature = data;
      this.showTnC = true;
    },
    signature_option: function () {
      this.showTnC = false;
      this.fabForm.signature = null;
      if(this.option_signature == '2') {
        this.showUpload = true;
      } else {
        this.showUpload = false;
      }
      this.fabForm.option_signature = this.option_signature;
    },
    clearSignature: function () {
      this.$refs.signaturePad.clearSignature();
      this.fabForm.signature = null;
      this.showTnC = false;
      this.fabForm.terms = null;
    },
    formatPrice(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    aggreeTerms: function () {
      this.$refs.modalForm.hide();
      this.fabForm.terms = "accepted";
    },
    disaggreeTerms: function () {
      this.$refs.modalForm.hide();
      this.fabForm.terms = null;
    },
    tnc: function () {
      this.loadingTnc = true;
      this.$refs.modalForm.show();
      axios
        .post("fab/tnc", this.fabForm)
        .then((response) => {
          this.tnc_bahasa = response.data.bahasa;
          this.tnc_english = response.data.english;
        })
        .catch((error) => {})
        .finally(() => {
          this.loadingTnc = false;
        });
    },
    download_file: function (data) {
      this.img_preview = axios.defaults.upload_path_preview+data;
      this.$refs.previewModal.show();

      // return http.status != 404;
      // window.location.href = axios.defaults.upload_path_preview+data;
      // this.isLoadingDownload = true;
      // let url = axios.defaults.download_assets_preview+'/';

      // // hide button submit pas login
      // const box = document.getElementsByClassName('wizard-card-footer clearfix')[0];
      // box.style.visibility = 'hidden';
      
      // // api request
      // axios({
      //   url: url+data,
      //   method: "GET",
      //   responseType: "blob", //before "blob"
      // }).then((response) => {

      //   // file download
      //   var fileDownload = require('js-file-download');
      //   fileDownload(response.data, data);
      // }).catch((error) => {
      //   this.expired(error, "Error");
      // }).finally(() => {

      //   // remove loading & show button lagi
      //   this.isLoadingDownload = false;
      //   const box = document.getElementsByClassName('wizard-card-footer clearfix')[0];
      //   box.style.visibility = 'visible';
      // });
    },
    closePreview: function(){
      this.$refs.previewModal.hide();
    },
    /** Function for Selfie */
    uploadSelfieSuccess: function(file, response) {
        this.errAttachment.selfie = null;
        this.fabForm.selfie = response.path;
    },
    sendingSelfieEvent: function(file, xhr, formData){
      formData.append('flag', 9);
    },
    errorSelfieEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.Selfie.removeAllFiles();
      this.fabForm.selfie = null;
    },
    completeSelfieEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.Selfie.removeAllFiles();
      }
    },
    removeSelfieFile: function(file, error, xhr) {
        this.fabForm.selfie = null;
        // axios
        //   .post("tools/remove-image", {path: this.fabForm.selfie})
        //   .then((response) => {
        //       this.fabForm.selfie = null;
        //       this.errAttachment.selfie = 'The Selfie field is required';
        //   })
        //   .finally(() => {
        //     this.isLoading = false;
        //   });
    },
    /** Function for Signature */
    uploadSignatureSuccess: function(file, response) {
        this.errAttachment.signature = null;
        this.fabForm.signature = response.path;
        this.showTnC = true;
    },
    sendingSignatureEvent: function(file, xhr, formData){
      formData.append('flag', 9);
    },
    errorSignatureEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.Signature.removeAllFiles();
      this.fabForm.signature = null;
      this.showTnC = false;
    },
    completeSignatureEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.Signature.removeAllFiles();
      }
    },
    removeSignatureFile: function(file, error, xhr) {
        this.fabForm.signature = null;
        this.showTnC = false;
    },
    /** End function for IPL PBB */
  },
  mounted() {
    this.init();
  },
};
</script>
