<template>
  <div>
    <b-row v-if="!loading">
      <b-col md="12">
        <b-button
          size="sm"
          v-if="showAddButton"
          v-on:click.prevent="addForm($event.target)"
          variant="primary"
        >
          <feather-icon icon="PlusSquareIcon" class="mr-50" />
          <span>{{ addButton }}</span>
        </b-button>
        &nbsp;
        <b-button
          size="sm"
          v-if="showImportButton"
          v-on:click.prevent="importForm($event.target)"
          variant="warning"
        >
          <feather-icon icon="PlusSquareIcon" class="mr-50" />
          <span>{{ importButton }}</span>
        </b-button>
      </b-col>
      <b-col md="2" sm="4" class="my-1">
        <b-form-group class="mb-0" v-if="filterOptions">
          <label class="d-inline-block text-sm-left mr-50">Per page</label>
          <b-form-select
            id="perPageSelect"
            size="sm"
            v-model="perPage"
            :options="pageOptions"
            class="w-50"
          />
        </b-form-group>
      </b-col>

      <!-- <b-col md="4" sm="8" class="my-1">&nbsp;</b-col> -->
      <b-col md="4" class="my-1">
        <b-form-group
          v-if="filterOptions"
          label="Filter"
          label-cols-sm="2"
          label-align-sm="left"
          label-size="sm"
          label-for="filterInput"
          class="mb-0"
        >
          <b-input-group col="2" size="sm">
            <b-form-input
              id="filterInput"
              v-model="filter"
              type="search"
              placeholder="Type to Search"
            />
            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
      </b-col>

      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="tableData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :redirect-detail="redirectDetail"
          @filtered="onFiltered"
          show-empty
        >
          <!-- <template #empty="scope">
            <h1>s</h1>
          </template>
          <template #emptyfiltered="scope">
            <h4>a</h4>
          </template> -->

          <template #table-caption>&nbsp;</template>

          <template #cell(start_date)="data">
            <span>{{ formatDateHumanRead(data.item.start_date) }}</span>
          </template>

          <template #cell(updated_time)="data">
            <span>{{ dateTimeFormat(data.item.updated_time) }}</span>
          </template>

          <template #cell(date_activation)="data">
              <span>{{ dateTimeFormat(data.item.date_activation) }}</span>
          </template>
          <template #cell(date_termination)="data">
              <span>{{ dateTimeFormat(data.item.date_termination) }}</span>
          </template>

          <template #cell(code_equipment)="data">
            <div>
              <div> {{data.item.code_equipment}}</div><br>
              <div class="text-uppercase"> <b>Subcription :</b></div>
              <span>{{ data.item.product_subscription }}</span>
            </div>
          </template>

          <template #cell(mac_address)="data">
            <div>
              <div class="text-uppercase"> <b>Mac Address :</b></div>
              <span> {{data.item.mac_address}}</span><br>
              <div class="text-uppercase"> <b>Serial Number :</b></div>
              <span>{{ data.item.serial_number }}</span><br>
              <div class="text-uppercase"> <b>Ip Address :</b></div>
              <span>{{ data.item.ip_address }}</span><br>
            </div>
          </template>

          <template #cell(tnc_bahasa)="data">
            <span v-html="data.item.tnc_bahasa"></span>
          </template>

          <template #cell(tnc_english)="data">
            <span v-html="data.item.tnc_english"></span>
          </template>

          <template #cell(price)="data">
            <span>Rp. {{ formatPrice(data.item.price) }}</span>
          </template>

          <template #cell(price_exclude_tax)="data">
            <span>Rp. {{ formatPrice(data.item.price_exclude_tax) }}</span>
          </template>

          <template #cell(discount_price)="data">
            <span>{{(data.item.discount_price != "" && data.item.discount_price != null) ? 'Rp. ' : ''}} {{ formatPrice(data.item.discount_price) }}</span>
          </template>

          <template #cell(total)="data">
            <span>Rp. {{ formatPrice(data.item.total) }}</span>
          </template>

          <template #cell(end_date)="data">
            <span>{{ formatDateHumanRead(data.item.end_date) }}</span>
          </template>

          <template #cell(action)="data">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>
                <b-dropdown-item
                  v-if="redirectDetail && editButton"
                  @click="goToDetail(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="redirectDetail && editButton && duplicateAdd"
                  @click="goToDuplicate(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Duplicate</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="!redirectDetail && editButton"
                  @click="editForm(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Edit</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!redirectDetail && detailLog"
                  @click="previewLog(data.item)"
                >
                  <feather-icon icon="EyeIcon" class="mr-50" />
                  <span>Preview</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!redirectDetail && addEquipment"
                  @click="addmoreEquipment(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Add Equipment</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!redirectDetail && editTermination"
                  @click="editmoreTermination(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Termination</span>
                </b-dropdown-item>

                <b-dropdown-item
                  v-if="!redirectDetail && addTelephoneNumber == true && data.item.telephone_flag == 1"
                  @click="addTelephone(data.item)"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>Add Telephone</span>
                </b-dropdown-item>
                
                <b-dropdown-item
                    v-if="!redirectDetail && previewButton"
                    @click="previewForm(data.item)"
                  >
                    <feather-icon icon="EyeIcon" class="mr-50" />
                    <span>Preview</span>
                  </b-dropdown-item>
                <div v-if="data.item.equipment_qty">  
                <div v-if="data.item.equipment_qty == 0">
                  <b-dropdown-item
                    v-if="removeButton"
                    @click="removeData(data.item)"
                  >
                  
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </div>
                </div>
                <div v-else>
                  <b-dropdown-item
                    v-if="removeButton"
                    @click="removeData(data.item)"
                  >
                  
                    <feather-icon icon="TrashIcon" class="mr-50" />
                    <span>Delete</span>
                  </b-dropdown-item>
                </div>
                <b-dropdown-item
                  v-if="downloadButton"
                  @click="downloadData(data.item)"
                >
                  <feather-icon icon="DownloadIcon" class="mr-50" />
                  <span>Download</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="previewPromotion"
                  @click="previewPromotionRelated(data.item)"
                >
                  <feather-icon icon="SearchIcon" class="mr-50" />
                  <span>Preview</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col><loading></loading></b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "@core/loading/Loading.vue";
import * as moment from "moment";

export default {
  name: "global-table",
  components: {
    Loading,
  },
  props: {
    striped: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
    fields: {
      type: [Array, Object],
      default: () => [],
    },
    dark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    redirectDetail: {
      type: Boolean,
      default: true,
    },
    duplicateAdd: {
      type: Boolean,
      default: false,
    },
    previewPromotion:{
      type: Boolean,
      default: false,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    detailLog: {
      type: Boolean,
      default: false,
    },
    addEquipment: {
      type: Boolean,
      default: false,
    },
    editTermination: {
      type: Boolean,
      default: false,
    },
    addTelephoneNumber: {
      type: Boolean,
      default: false,
    },
    previewButton: {
      type: Boolean,
      default: false,
    },
    removeButton: {
      type: Boolean,
      default: false,
    },
    downloadButton: {
      type: Boolean,
      default: false,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    showImportButton: {
      type: Boolean,
      default: false,
    },
    filterOptions: {
      type: Boolean,
      default: true,
    },
    addButton: {
      type: String,
      default: "Add Form",
    },
    importButton: {
      type: String,
      default: "Import Data",
    },
    customVariableId: {
      type: String,
      default: "id",
    },
    idModals: {
      type: String,
      default: "addModals",
    },
  },
  data() {
    return {
      pageOptions: [5, 10, 20],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      perPage: 10,
      newlength: 0,
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    totalRows() {
      if(this.newlength>0){
        return this.newlength;
      }else{
        return this.tableData.length > 1 ? this.tableData.length : 1;
      }
    },
  },
  mounted() {
    // this.totalRows = this.tableData.length > 1 ? this.tableData.length : 1;
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.newlength = filteredItems.length;
      this.currentPage = 1;
    },
    addForm: function (button) {
      this.$emit("isEdit", false);
      this.$emit("resetForm");
      this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    importForm: function (button) {
      this.$emit("importForm");
      //this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    removeData: function (row) {
      this.$emit("removeData", row);
    },
    downloadData: function (row) {
      this.$emit("downloadData", row);
    },
    goToDetail: function (row) {
      let path = this.$route.path.split("/");
      this.$router.push({
        path: path[2] + "/detail/" + row[this.customVariableId],
      });
    },
    goToDuplicate: function (row) {
      this.$emit("editForm", row);
      this.$emit("isEdit", true);
      this.$root.$emit("bv::show::modal", this.idModals);
    },
    editForm: function (row) {
      this.$emit("editForm", row);
      this.$emit("isEdit", true);
      this.$root.$emit("bv::show::modal", this.idModals);
    },
    previewLog: function (row) {
      this.$emit("previewLog", row);
    },
    addmoreEquipment: function (row) {
      this.$emit("addEquipment", row);
      this.$emit("isEdit", true);
    },
    previewPromotionRelated: function(row){
      this.$emit("viewPromotionRelated", row);
    },
    editmoreTermination: function (row) {
      this.$emit("editTermination", row);
      this.$emit("isEdit", true);
    },
    addTelephone: function (row) {
      this.$emit("addTelephone", row);
      this.$emit("isEdit", true);
    },
    previewForm: function (row) {
      this.$emit("previewForm", row);
      this.$emit("isEdit", true);
    },
    formatPrice(value) {
      let val = (value / 1).toFixed(0).replace(".", ",");
      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    dateTimeFormat: function (date) {
      if (date) {
        return moment(date).format("DD MMM YYYY HH:mm:ss");
      }
    },
    formatDateHumanRead: function (date) {
      if (date) {
        return moment(date).format("DD MMMM YYYY");
      }
    },
    formatPrice(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = (value / 1).toFixed(0).replace(".", ",");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
      }
    },
    formatPercentage(value) {
      if (value === null || value == null || value == "" || value === "") {
        return "";
      } else {
        let val = value + "%";
        return val;
      }
    },
  },
};
</script>

<style>
  .table-responsive {
    margin-bottom: -2rem;
  }
</style>