var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Customer Site"}},[_c('validation-provider',{attrs:{"name":"Customer Site","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"cust_area","placeholder":"Customer Site","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.site_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "site_name", $$v)},expression:"fabForm.site_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Customer Group"}},[_c('validation-provider',{attrs:{"name":"Customer Group","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"cust_group","placeholder":"Customer Group","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.group_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "group_name", $$v)},expression:"fabForm.group_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Customer Type"}},[_c('validation-provider',{attrs:{"name":"Customer Type","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"cust_type","placeholder":"Customer Type","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.cust_type_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "cust_type_name", $$v)},expression:"fabForm.cust_type_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Location"}},[_c('validation-provider',{attrs:{"name":"Location","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"loc","placeholder":"Location","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.location_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "location_name", $$v)},expression:"fabForm.location_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Location Area"}},[_c('validation-provider',{attrs:{"name":"Location Area","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"loc_area","placeholder":"Location","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.location_area_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "location_area_name", $$v)},expression:"fabForm.location_area_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Address"}},[_c('validation-provider',{attrs:{"name":"Address","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown,"placeholder":"-- Please select --","state":errors.length > 0 ? false : null},on:{"input":_vm.autofill_other_address},model:{value:(_vm.fabForm.location_address),callback:function ($$v) {_vm.$set(_vm.fabForm, "location_address", $$v)},expression:"fabForm.location_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Tax Address"}},[_c('validation-provider',{attrs:{"name":"Tax Address","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","id":"i-facebook","placeholder":"Alamat","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.tax_address),callback:function ($$v) {_vm.$set(_vm.fabForm, "tax_address", $$v)},expression:"fabForm.tax_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Invoice Address"}},[_c('validation-provider',{attrs:{"name":"Invoice Address","rules":_vm.requiredValidation},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"rows":"3","id":"i-facebook","placeholder":"Alamat","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.invoice_address),callback:function ($$v) {_vm.$set(_vm.fabForm, "invoice_address", $$v)},expression:"fabForm.invoice_address"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Zipcode","label-for":"Zipcode"}},[_c('validation-provider',{attrs:{"name":"Zipcode","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"zipcode","raw":false,"options":_vm.options.zipcode,"placeholder":"Zipcode"},model:{value:(_vm.fabForm.zipcode),callback:function ($$v) {_vm.$set(_vm.fabForm, "zipcode", $$v)},expression:"fabForm.zipcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Status Lokasi"}},[_c('validation-provider',{attrs:{"name":"status lokasi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_status_lokasi,"name":"radio-inline2","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.status_lokasi),callback:function ($$v) {_vm.$set(_vm.fabForm, "status_lokasi", $$v)},expression:"fabForm.status_lokasi"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Deposit"}},[_c('validation-provider',{attrs:{"name":"deposit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_deposit,"name":"radio-inline3","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.deposit),callback:function ($$v) {_vm.$set(_vm.fabForm, "deposit", $$v)},expression:"fabForm.deposit"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[(_vm.fabForm.deposit=='1')?_c('b-form-group',{attrs:{"label":"Total Deposit","label-for":"total_deposit"}},[_c('validation-provider',{attrs:{"name":"Total deposit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('model-select',{attrs:{"options":_vm.dropdown_deposit,"placeholder":"-- Please select --","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.deposit_amount),callback:function ($$v) {_vm.$set(_vm.fabForm, "deposit_amount", $$v)},expression:"fabForm.deposit_amount"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,262083697)})],1):_vm._e()],1),_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Pelanggan Lama ?"}},[_c('validation-provider',{attrs:{"name":"returnee","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_returnee,"name":"radio-inline4","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.returnee),callback:function ($$v) {_vm.$set(_vm.fabForm, "returnee", $$v)},expression:"fabForm.returnee"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),(_vm.fabForm.returnee === 1)?_c('b-col',{attrs:{"lg":"4","md":"6"}},[_c('b-form-group',{attrs:{"label":"Kode pelanggan anda sebelumnya","label-for":"returnee_customer_code"}},[_c('validation-provider',{attrs:{"name":"Returnee Customer Code","rules":"required|min:10"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"returnee_customer_code","raw":false,"options":_vm.options.returnee_customer_code,"placeholder":"Returnee Customer Code"},model:{value:(_vm.fabForm.returnee_customer_code),callback:function ($$v) {_vm.$set(_vm.fabForm, "returnee_customer_code", $$v)},expression:"fabForm.returnee_customer_code"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2590465602)})],1)],1):_vm._e(),_vm._l((_vm.questioner),function(options_list,question){return _c('b-col',{key:question,attrs:{"lg":"4","md":"6","label":question}},[_c('validation-provider',{attrs:{"name":options_list.name,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{key:question,attrs:{"label":question}},[(options_list.type == 1)?_c('div',_vm._l((options_list.options),function(options_detail,options_index){return _c('b-form-radio',{key:options_index,attrs:{"name":options_list.id_question,"value":options_detail.value},on:{"input":_vm.rerfeshValueEmit},model:{value:(_vm.answer[options_list.id_question]),callback:function ($$v) {_vm.$set(_vm.answer, options_list.id_question, $$v)},expression:"answer[options_list.id_question]"}},[_vm._v(_vm._s(options_detail.text))])}),1):_vm._e(),(options_list.type == 2)?_c('div',[_c('b-form-checkbox-group',{attrs:{"options":options_list.options,"stacked":""},on:{"input":_vm.rerfeshValueEmit},model:{value:(_vm.answer[options_list.id_question]),callback:function ($$v) {_vm.$set(_vm.answer, options_list.id_question, $$v)},expression:"answer[options_list.id_question]"}})],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])])]}}],null,true)})],1)})],2)}
var staticRenderFns = []

export { render, staticRenderFns }