var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('b-row',[_c('b-col',{attrs:{"lg":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Customer Name","label-for":"name"}},[_c('validation-provider',{attrs:{"name":"Customer Title","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticStyle:{"width":"20%","float":"left"},attrs:{"options":_vm.dropdown_customer_title,"state":errors.length > 0 ? false : null},on:{"input":_vm.change_title},model:{value:(_vm.fabForm.title),callback:function ($$v) {_vm.$set(_vm.fabForm, "title", $$v)},expression:"fabForm.title"}})]}}],null,false,2412828976)}),_c('validation-provider',{attrs:{"name":"Customer Name","rules":"required|min:3|regex:^([a-zA-Z0-9.\\-\\s]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"80%","float":"left"},attrs:{"id":"customer_nama","placeholder":"Nama","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.nama),callback:function ($$v) {_vm.$set(_vm.fabForm, "nama", $$v)},expression:"fabForm.nama"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2321943646)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Phone Number","label-for":"customer_phone"}},[_c('validation-provider',{attrs:{"name":"Phone Number","rules":"required|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"disabled":"","id":"customer_phone","raw":false,"options":_vm.options.phone,"placeholder":"Phone Number"},model:{value:(_vm.fabForm.phone),callback:function ($$v) {_vm.$set(_vm.fabForm, "phone", $$v)},expression:"fabForm.phone"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2678224028)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Handphone","label-for":"customer_hp"}},[_c('validation-provider',{attrs:{"name":"Handphone","rules":"required|min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"customer_hp","raw":false,"options":_vm.options.hp,"placeholder":"Handphone"},model:{value:(_vm.fabForm.hp),callback:function ($$v) {_vm.$set(_vm.fabForm, "hp", $$v)},expression:"fabForm.hp"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,388085854)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Handphone 2","label-for":"handphone2"}},[_c('validation-provider',{attrs:{"name":"Handphone 2","rules":"min:9"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"handphone2","raw":false,"options":_vm.options.fax,"placeholder":"Handphone 2"},model:{value:(_vm.fabForm.fax),callback:function ($$v) {_vm.$set(_vm.fabForm, "fax", $$v)},expression:"fabForm.fax"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,838122818)})],1)],1)],1),_c('b-row',[(_vm.Show_pic_name)?_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"PIC Name","label-for":"PIC Name"}},[_c('validation-provider',{attrs:{"name":"PIC Title","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-select',{staticStyle:{"width":"20%","float":"left"},attrs:{"options":_vm.dropdown_customer_title,"state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.pic_title),callback:function ($$v) {_vm.$set(_vm.fabForm, "pic_title", $$v)},expression:"fabForm.pic_title"}})]}}],null,false,2197347921)}),_c('validation-provider',{attrs:{"name":"PIC Name","rules":"alpha_spaces"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{staticStyle:{"width":"80%","float":"left"},attrs:{"id":"pic_nama","placeholder":"Nama","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.pic_name),callback:function ($$v) {_vm.$set(_vm.fabForm, "pic_name", $$v)},expression:"fabForm.pic_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3457238913)})],1)],1):_vm._e()],1),_c('b-row',[_c('b-col',{attrs:{"lg":"3","md":"12"}},[_c('b-form-group',{attrs:{"label":"Option"}},[_c('validation-provider',{attrs:{"name":"Option","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{attrs:{"options":_vm.options_ktp_passport,"name":"radio-option","state":errors.length > 0 ? false : null},on:{"change":_vm.change_ktp_passport},model:{value:(_vm.fabForm.ktp_passport),callback:function ($$v) {_vm.$set(_vm.fabForm, "ktp_passport", $$v)},expression:"fabForm.ktp_passport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3108700517)})],1)],1),(_vm.Show_ktp)?_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"ID Number","label-for":"id_number"}},[_c('validation-provider',{attrs:{"name":"ID Number","rules":"required|min:19"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"id_number","raw":false,"options":_vm.options.ktp,"placeholder":"0000-0000-0000-0000"},model:{value:(_vm.fabForm.ktp),callback:function ($$v) {_vm.$set(_vm.fabForm, "ktp", $$v)},expression:"fabForm.ktp"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The ID Number field must be at least 16 characters")]):_vm._e()]}}],null,false,377479306)})],1)],1):_vm._e(),(_vm.Show_passport)?_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Passport","label-for":"passport"}},[_c('validation-provider',{attrs:{"name":"Passport","rules":"required|min:5"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{staticClass:"form-control",class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"passport","raw":false,"options":_vm.options.passport,"placeholder":"000000000"},model:{value:(_vm.fabForm.passport),callback:function ($$v) {_vm.$set(_vm.fabForm, "passport", $$v)},expression:"fabForm.passport"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,611758534)})],1)],1):_vm._e(),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"NPWP","label-for":"customer_npwp"}},[_c('validation-provider',{attrs:{"name":"NPWP","rules":"required|min:21"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('cleave',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"id":"customer_npwp","disabled":_vm.Show_option,"raw":false,"options":_vm.options.npwp,"placeholder":"000.000.000.0-000.000"},model:{value:(_vm.fabForm.npwp),callback:function ($$v) {_vm.$set(_vm.fabForm, "npwp", $$v)},expression:"fabForm.npwp"}}),(errors[0])?_c('small',{staticClass:"text-danger"},[_vm._v("The NPWP field must be at least 16 characters")]):_vm._e()]}}],null,false,1146926827)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Kota Kelahiran","label-for":"Kota Kelahiran"}},[_c('validation-provider',{attrs:{"name":"Kota Kelahiran","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"dob_place","placeholder":"Kota Kelahiran","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.dob_place),callback:function ($$v) {_vm.$set(_vm.fabForm, "dob_place", $$v)},expression:"fabForm.dob_place"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1534951739)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Tanggal Lahir","label-for":"customer_dob"}},[_c('validation-provider',{attrs:{"name":"Tanggal Lahir","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{class:errors.length === 0 ? 'form-control' : 'form-control is-invalid',attrs:{"placeholder":"Tanggal Lahir"},model:{value:(_vm.fabForm.dob),callback:function ($$v) {_vm.$set(_vm.fabForm, "dob", $$v)},expression:"fabForm.dob"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1385685702)})],1)],1),_c('b-col',{attrs:{"lg":"3","md":"6"}},[_c('b-form-group',{attrs:{"label":"Email","label-for":"customer_email"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"disabled":"","id":"customer_email","placeholder":"Email","state":errors.length > 0 ? false : null},model:{value:(_vm.fabForm.email),callback:function ($$v) {_vm.$set(_vm.fabForm, "email", $$v)},expression:"fabForm.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2273854404)})],1)],1),_c('b-col',{attrs:{"lg":"3","col-md-4":""}},[_c('b-form-group',{attrs:{"label":"Email Secondary","label-for":"customer_email_secondary"}},[_c('validation-provider',{attrs:{"name":"Email_secondary","rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('tag-input',{attrs:{"value":_vm.defaultValueTag},on:{"responseTagInput":_vm.responseTagInput}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3654841604)})],1)],1)],1)],1):_c('div',[_c('b-col',[_c('loading')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }