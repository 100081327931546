<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Jenis Pembayaran Pelanggan</h5>
    </b-col>
    <b-col lg="12" md="6">
      <b-form-group label="Periode Penagihan">
        <validation-provider #default="{ errors }" name="Periode Berlangganan" :rules="requiredValidation">
          <b-form-radio-group :options="options_periode_berlangganan" v-model="fabForm.subscription_periode" name="radio-periode-berlangganan" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="12" md="6">
      <b-form-group label="Jenis Pembayaran">
        <validation-provider #default="{ errors }" name="jenis Pembayaran" :rules="requiredValidation">
          <b-form-radio-group :options="options_jenis_pembayaran" v-model="fabForm.payment_type" name="radio-jenis-pembayaran" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  name: "customer-information",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    required,
    ToastificationContent,
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      options_periode_berlangganan: [
        { text: "Bulanan", value: "1" },
        { text: "Lainnya", value: "2" },
      ],
      options_jenis_pembayaran: [
        //{ text: "Cek", value: "2" },
        { text: "Virtual Account", value: "1" },
        { text: "Autodebet", value: "2" },
      ],
    };
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
      this.isLoading = true;
    },
  },
  mounted() {
    this.init();
  },
};
</script>
