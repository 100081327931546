<template>
  <b-row>
    <b-col cols="12" class="mb-2">
      <h5 class="mb-0">Lampiran data kelengkapan pelanggan.</h5>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="KTP / KITAS">
        <b-form-radio-group :options="fabForm.ktp == '0000-0000-0000-0000' || fabForm.passport == '000000000' || fabForm.title == 5 || fabForm.title == 6 || fabForm.title == 7 ? options_lampiran : options_lampiran_wajib" @change="clear_attachment('ktp')" v-model="fabForm.lampiran_ktp" name="radio-ktp"></b-form-radio-group>
        <br />
        <validation-provider v-if="fabForm.lampiran_ktp == 1" #default="{ errors }" name="KTP">
            <vue-dropzone ref="myKtp" id="ktp" name="ktp"
              v-on:vdropzone-sending="sendingKtpEvent"
              v-on:vdropzone-complete="completeKtpEvent" 
              v-on:vdropzone-success="uploadKtpSuccess"
              v-on:vdropzone-error="errorKtpEvent" 
              v-on:vdropzone-removed-file="removeKtpFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <!-- <b-form-file :state="errors.length > 0 ? false : null" v-model="fabForm.file_lampiran_ktp" ref="file-input" class="mb-2" required></b-form-file> -->
          <small class="text-danger">{{ (errAttachment.ktp != '') ? errAttachment.ktp: errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="NPWP">
        <b-form-radio-group :options="fabForm.title == 5 || fabForm.title == 6 || fabForm.title == 7 ? options_lampiran_wajib : options_lampiran" @change="clear_attachment('npwp')" v-model="fabForm.lampiran_npwp" name="radio-npwp"></b-form-radio-group>
        <br />
        <validation-provider v-if="fabForm.lampiran_npwp == 1" #default="{ errors }" name="NPWP">
           <vue-dropzone ref="myNpwp" id="npwp" name="npwp"
              v-on:vdropzone-sending="sendingNpwpEvent"
              v-on:vdropzone-complete="completeNpwpEvent" 
              v-on:vdropzone-success="uploadNpwpSuccess"
              v-on:vdropzone-error="errorNpwpEvent" 
              v-on:vdropzone-removed-file="removeNpwpFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <!-- <b-form-file :state="errors.length > 0 ? false : null" v-model="fabForm.file_lampiran_npwp" ref="file-input_npwp" class="mb-2" required></b-form-file> -->
          <small class="text-danger">{{ (errAttachment.npwp != '') ? errAttachment.npwp: errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="SIUP">
        <validation-provider #default="{ errors }" name="SIUP" :rules="requiredValidation">
          <b-form-radio-group :options="options_lampiran" @change="clear_attachment('siup')" v-model="fabForm.lampiran_siup" name="radio-siup" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <br />
        <validation-provider v-if="fabForm.lampiran_siup == 1" #default="{ errors }" name="File SIUP">
          <!-- <b-form-file v-model="fabForm.file_lampiran_siup" ref="file-input_siup" class="mb-2" required></b-form-file> -->
          <vue-dropzone ref="mySiup" id="siup" name="siup"
              v-on:vdropzone-sending="sendingSiupEvent"
              v-on:vdropzone-complete="completeSiupEvent" 
              v-on:vdropzone-success="uploadSiupSuccess"
              v-on:vdropzone-error="errorSiupEvent" 
              v-on:vdropzone-removed-file="removeSiupFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <small class="text-danger">{{ (errAttachment.siup != '') ? errAttachment.siup: errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="NIB">
        <validation-provider #default="{ errors }" name="NIB" :rules="requiredValidation">
          <b-form-radio-group :options="options_lampiran" @change="clear_attachment('nib')" v-model="fabForm.lampiran_nib" name="radio-tdp" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <br />
        <validation-provider v-if="fabForm.lampiran_nib == 1" #default="{ errors }" name="File NIB">
          <!-- <b-form-file v-model="fabForm.file_lampiran_nib" ref="file-input_nib" class="mb-2" required></b-form-file> -->
          <vue-dropzone ref="myNib" id="nib" name="nib"
              v-on:vdropzone-sending="sendingNibEvent"
              v-on:vdropzone-complete="completeNibEvent" 
              v-on:vdropzone-success="uploadNibSuccess"
              v-on:vdropzone-error="errorNibEvent" 
              v-on:vdropzone-removed-file="removeNibFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <small class="text-danger">{{ (errAttachment.nib != '') ? errAttachment.nib: errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="Akta Perusahaan">
        <validation-provider #default="{ errors }" name="Akta Perusahaan" :rules="requiredValidation">
          <b-form-radio-group :options="options_lampiran" @change="clear_attachment('akta')" v-model="fabForm.lampiran_akta_perusahaan" name="radio-akta" :state="errors.length > 0 ? false : null"></b-form-radio-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
        <br />
        <validation-provider v-if="fabForm.lampiran_akta_perusahaan == 1" #default="{ errors }" name="File Akta Perusahaan">
          <!-- <b-form-file v-model="fabForm.file_lampiran_akta" ref="file-input_akta" class="mb-2" required></b-form-file> -->
          <vue-dropzone ref="myAktaPerusahaan" id="akta_perusahaan" name="akta_perusahaan"
              v-on:vdropzone-sending="sendingAktaPerusahaanEvent"
              v-on:vdropzone-complete="completeAktaPerusahaanEvent" 
              v-on:vdropzone-success="uploadAktaPerusahaanSuccess"
              v-on:vdropzone-error="errorAktaPerusahaanEvent" 
              v-on:vdropzone-removed-file="removeAktaPerusahaanFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <small class="text-danger">{{ (errAttachment.akta_perusahaan != '') ? errAttachment.akta_perusahaan: errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </b-col>
    <b-col lg="4" md="6">
      <b-form-group label="IPL / PBB">
          <b-form-radio-group :options="fabForm.deposit == '1' ? options_lampiran : options_lampiran_wajib" @change="clear_attachment('ipl')" v-model="fabForm.lampiran_ipl_pbb" name="radio-ipl-pbb" ></b-form-radio-group>
        <br />
        <validation-provider v-if="fabForm.lampiran_ipl_pbb == 1" #default="{ errors }" name="File IPL / PBB">
          <vue-dropzone ref="myIplPbb" id="ipl_pbb" name="ipl_pbb"
              v-on:vdropzone-sending="sendingIplPbbEvent"
              v-on:vdropzone-complete="completeIplPbbEvent" 
              v-on:vdropzone-success="uploadIplPbbSuccess"
              v-on:vdropzone-error="errorIplPbbEvent" 
              v-on:vdropzone-removed-file="removeIplPbbFile" 
              :options="dropzoneOptions"></vue-dropzone>
          <small class="text-danger">{{ (errAttachment.ipl_pbb != '') ? errAttachment.ipl_pbb: errors[0] }}</small>
          <!-- <b-form-file v-model="fabForm.file_lampiran_ipl_pbb" ref="file-input_ipl-pbb" class="mb-2" required></b-form-file> -->
        </validation-provider>
      </b-form-group>
    </b-col>
  </b-row>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import axios from "axios";
import Loading from "@core/loading/Loading.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Multiselect from "vue-multiselect";
import vue2Dropzone from 'vue2-dropzone';

export default {
  name: "customer-address",
  components: {
    ValidationProvider,
    ValidationObserver,
    Loading,
    Multiselect,
    required,
    ToastificationContent,
    vueDropzone: vue2Dropzone
  },
  props: {
    requiredValidation: {
      type: String,
      default: "required",
    },
    fabForm: {
      type: [Array, Object],
      default: () => {},
    },
  },
  data() {
    return {
      options_lampiran: [
        { text: "Ya", value: "1" },
        { text: "Tidak", value: "0" },
      ],
      options_lampiran_wajib: [
        { text: "Ya", value: "1" },
      ],
      isLoading: false,
      errAttachment: {
        ktp: null,
        npwp: null,
        siup: null,
        nib: null,
        akta_perusahaan: null,
        ipl_pbb: null

      },
      dropzoneOptions: {
          url: axios.defaults.baseURL+'upload/add-assets',
          //url: 'https://apiefab.netciti.co.id/netciti-api/public/tools/upload',
          //url: 'http://10.3.0.231/netciti-api/public/tools/upload',
          thumbnailWidth: 150,
          maxFilesize: 5,
          paramName:"attachment",
          disablePreviews: true,
          maxFiles: 1,
          addRemoveLinks: true,
          acceptedFiles: "image/*,application/pdf",
          headers: { 
              'Cache-Control': '',
          }
      }
    };
  },
  methods: {
    init: function () {
      // this.fabForm.title = 1;
      this.fabForm.lampiran_ktp = 1;
      //this.fabForm.lampiran_npwp = 0;
      this.isLoading = true;
    },
    /** Function for KTP */
    uploadKtpSuccess: function(file, response) {
        this.errAttachment.ktp = null;
        this.fabForm.file_lampiran_ktp = response.path;
    },
    sendingKtpEvent: function(file, xhr, formData){
      formData.append('flag', 1);
    },
    errorKtpEvent: function(file, error, xhr) {
      // console.log(error);
      this.$refs.myKtp.removeAllFiles();
    },
    completeKtpEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.myKtp.removeAllFiles();
      }
    },
    removeKtpFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_ktp})
          .then((response) => {
              this.fabForm.file_lampiran_ktp = null;
              this.errAttachment.ktp = 'KTP field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for KTP */

    /** Function for KTP */
    uploadNpwpSuccess: function(file, response) {
        this.errAttachment.npwp = null;
        this.fabForm.file_lampiran_npwp = response.path;
    },
    sendingNpwpEvent: function(file, xhr, formData){
      formData.append('flag', 2);
    },
    errorNpwpEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.myNpwp.removeAllFiles();
    },
    completeNpwpEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.myNpwp.removeAllFiles();
      }
    },
    removeNpwpFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_npwp})
          .then((response) => {
            this.fabForm.file_lampiran_npwp = null;
            this.errAttachment.npwp = 'The NPWP field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for NPWP */

    /** Function for SIUP */
    uploadSiupSuccess: function(file, response) {
        this.errAttachment.siup = null;
        this.fabForm.file_lampiran_siup = response.path;
    },
    sendingSiupEvent: function(file, xhr, formData){
      formData.append('flag', 3);
    },
    errorSiupEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.mySiup.removeAllFiles();
    },
    completeSiupEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.mySiup.removeAllFiles();
      }
    },
    removeSiupFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_siup})
          .then((response) => {
            this.fabForm.file_lampiran_siup = null;
            this.errAttachment.siup = 'The SIUP field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for SIUP */


    /** Function for NIB */
    uploadNibSuccess: function(file, response) {
        this.errAttachment.nib = null;
        this.fabForm.file_lampiran_nib = response.path;
    },
    sendingNibEvent: function(file, xhr, formData){
      formData.append('flag', 8);
    },
    errorNibEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.myNib.removeAllFiles();
    },
    completeNibEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.myNib.removeAllFiles();
      }
    },
    removeNibFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_nib})
          .then((response) => {
            this.fabForm.file_lampiran_nib = null;
            this.errAttachment.nib = 'The NIB field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for NIB */


    /** Function for AKTA PERUSAHAAN */
    uploadAktaPerusahaanSuccess: function(file, response) {
        this.errAttachment.akta_perusahaan = null;
        this.fabForm.file_lampiran_akta_perusahaan = response.path;
    },
    sendingAktaPerusahaanEvent: function(file, xhr, formData){
      formData.append('flag', 5);
    },
    errorAktaPerusahaanEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.myAktaPerusahaan.removeAllFiles();
    },
    completeAktaPerusahaanEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.myAktaPerusahaan.removeAllFiles();
      }
    },
    removeAktaPerusahaanFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_akta_perusahaan})
          .then((response) => {
            this.fabForm.file_lampiran_akta_perusahaan = null;
            this.errAttachment.akta_perusahaan = 'The Akta Perusahaan field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for AKTA PERUSAHAAN */

     /** Function for IPL PBB */
    uploadIplPbbSuccess: function(file, response) {
        this.errAttachment.ipl_pbb = null;
        this.fabForm.file_lampiran_ipl_pbb = response.path;
    },
    sendingIplPbbEvent: function(file, xhr, formData){
      formData.append('flag', 10);
    },
    errorIplPbbEvent: function(file, error, xhr) {
      alert(error);
      this.$refs.myIplPbb.removeAllFiles();
    },
    completeIplPbbEvent: function(param) {
      if(param.status === 'error') {
        this.$refs.myIplPbb.removeAllFiles();
      }
    },
    removeIplPbbFile: function(file, error, xhr) {
        axios
          .post("tools/remove-image", {path: this.fabForm.file_lampiran_ipl_pbb})
          .then((response) => {
              this.fabForm.file_lampiran_ipl_pbb = null;
              this.errAttachment.ipl_pbb = 'The IPL PBB field is required';
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    /** End function for IPL PBB */


    clear_attachment: function(val){
      switch (val) {
        case 'siup':
          this.fabForm.file_lampiran_siup = null
          break;

        case 'nib':
          this.fabForm.file_lampiran_nib = null
          break;
        
        case 'akta':
          this.fabForm.file_lampiran_akta = null
          break;

        case 'ipl':
          this.fabForm.file_lampiran_ipl_pbb = null
          break;

        case 'ktp':
          this.fabForm.file_lampiran_ktp = null
          break;

        case 'npwp':
          this.fabForm.file_lampiran_npwp = null
          break;
      
        default:
          break;
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>

<style>
  .dz-error-message {
    top: -1px;
  }
</style>