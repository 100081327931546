<template>
  <div>
    <b-row v-if="!loading">
      <b-col cols="12">
        <b-table
          striped
          hover
          responsive
          :per-page="perPage"
          :current-page="currentPage"
          :items="tableData"
          :fields="fields"
          :sort-by.sync="sortBy"
          :sort-desc.sync="sortDesc"
          :sort-direction="sortDirection"
          :filter="filter"
          :filter-included-fields="filterOn"
          :redirect-detail="redirectDetail"
          @filtered="onFiltered"
        >
          <template #table-caption>&nbsp;</template>

          <template #cell(show_details)="row">
            <b-form-checkbox
              v-model="row.detailsShowing"
              plain
              class="vs-checkbox-con"
              @change="row.toggleDetails"
            >
              <span class="vs-checkbox">
                <span class="vs-checkbox--check">
                  <i class="vs-icon feather icon-check" />
                </span>
              </span>
              <span class="vs-label">{{ row.detailsShowing ? "Hide" : "Show" }}</span>
            </b-form-checkbox>
          </template>

          <template #row-details="row">
            <b-card>
              <b-row class="mb-2">
                <b-col md="4" class="mb-1">
                  <strong>Full Name : </strong>{{ row.item.text }}
                </b-col>
                <b-col md="4" class="mb-1">
                  <strong>Post : </strong>{{ row.item.price }}
                </b-col>
              </b-row>
            </b-card>
          </template>

          <template #cell(action)="data">
            <b-button
              v-if="redirectDetail && editButton"
              type="button"
              @click="goToDetail(data.item)"
              size="sm"
              variant="flat-primary"
            >
              <feather-icon icon="EditIcon" /> Edit
            </b-button>
            <b-button
              v-if="!redirectDetail && editButton"
              type="button"
              @click="editForm(data.item)"
              size="sm"
              variant="flat-primary"
            >
              <feather-icon icon="EditIcon" /> Edit
            </b-button>
          </template>
        </b-table>
      </b-col>

      <b-col cols="12">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          align="right"
          size="sm"
          class="my-0"
        />
      </b-col>
    </b-row>

    <b-row v-else>
      <b-col><loading></loading></b-col>
    </b-row>
  </div>
</template>

<script>
import Loading from "@core/loading/Loading.vue";

export default {
  name: "global-table",
  components: {
    Loading,
  },
  props: {
    striped: {
      type: Boolean,
      default: false,
    },
    pagination: {
      type: Boolean,
      default: true,
    },
    bordered: {
      type: Boolean,
      default: false,
    },
    tableData: {
      type: [Array, Object],
      default: () => [],
    },
    fields: {
      type: [Array, Object],
      default: () => [],
    },
    perPage: {
      type: Number,
      default: 5,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    redirectDetail: {
      type: Boolean,
      default: true,
    },
    editButton: {
      type: Boolean,
      default: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    filterOptions: {
      type: Boolean,
      default: true,
    },
    addButton: {
      type: String,
      default: "Add Form",
    },
    customVariableId: {
      type: String,
      default: "id",
    },
    idModals: {
      type: String,
      default: "addModals",
    },
  },
  data() {
    return {
      pageOptions: [5, 10, 20],
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
    totalRows() {
      if (this.tableData) {
        return this.tableData !== undefined ? this.tableData.length : 1;
      }
    },
  },
  methods: {
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addForm: function (button) {
      this.$emit("isEdit", false);
      this.$emit("resetForm");
      this.$root.$emit("bv::show::modal", this.idModals, button);
    },
    goToDetail: function (row) {
      let path = this.$route.path.split("/");
      this.$router.push({
        path: path[2] + "/detail/" + row[this.customVariableId],
      });
    },
    editForm: function (row) {
      this.$emit("editForm", row);
      this.$emit("isEdit", true);
      this.$root.$emit("bv::show::modal", this.idModals);
    },
  },
};
</script>

<style>
  .table-responsive {
    margin-bottom: -2rem;
  }
</style>