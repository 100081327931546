<template>
  <div class="auth-wrapper auth-v1 px-2">
    <div class="auth-inner py-2">
      <!-- Login v1 -->
      <b-card class="mb-0">
        <b-link class="brand-logo">
          <span class="brand-logo">
            <b-img
              :src="appLogoImage"
              alt="logo"
              style="margin-bottom: 40px"
            />
          </span>
        </b-link>

        <b-card-text class="mb-2" style="text-align: center;"><h1>Customer Form</h1></b-card-text
        >
          <div>
            <form-wizard
              color="#7367F0"
              :title="null"
              :subtitle="null"
              finish-button-text="Submit"
              back-button-text="Previous"
              class="steps-transparent mb-3"
              @on-complete="formSubmitted"
            >
              <!-- Informasi Pribadi -->
              <tab-content
                title="Informasi Pribadi"
                icon="feather icon-user"
                :before-change="validationFormInformation"
              >
                <validation-observer ref="informasiPribadi" tag="form">
                  <customer-information :fab-form="fabForm"></customer-information>
                </validation-observer>
              </tab-content>
            
              <!-- Informasi Alamat -->
              <tab-content
                title="Alamat"
                icon="feather icon-map-pin"
                :before-change="validationFormAlamat"
              >
                <validation-observer ref="informasiAlamat" tag="form">
                  <customer-address
                    :fab-form="fabForm"
                    :dropdown="dropdownAddress"
                    @refreshQuestioner="refreshQuestionAnswer"
                  ></customer-address>
                </validation-observer>
              </tab-content>
            
              <!-- Informasi Layanan -->
              <tab-content
                title="Layanan"
                icon="feather icon-link"
                :before-change="validationFormLayanan"
              >
                <validation-observer ref="informasiLayanan" tag="form">
                  <customer-subscription-new
                    ref="custSubs"
                    :fab-form="fabForm"
                    :products-new="dropdown_product_list_new"
                    @finalizeArray="finalize_selected_product"
                    :questioner_result_suggestion="questioner_result_suggestion"
                  ></customer-subscription-new>
                </validation-observer>
              </tab-content>
              <!-- <tab-content title="Layanan" icon="feather icon-link" :before-change="validationFormLayanan">
                  <validation-observer ref="informasiLayanan" tag="form">
                    <customer-subscription :fab-form="fabForm" :products="dropdown_product_list" :products-ori="dropdown_product_list" :line-telephone="lineTelephone" @refreshDopdown="refresh_product_dropdown"></customer-subscription>
                  </validation-observer>
                </tab-content> -->
              
              <!-- Informasi Lampiran -->
              <tab-content
                title="Lampiran"
                icon="feather icon-file-text"
                :before-change="validationFormLampiran"
              >
                <validation-observer ref="informasiLampiran" tag="form">
                  <customer-attachment :fab-form="fabForm"></customer-attachment>
                </validation-observer>
              </tab-content>
            
              <!-- Informasi Pembayaran -->
              <tab-content
                title="Pembayaran"
                icon="feather icon-dollar-sign"
                :before-change="validationFormPembayaran"
              >
                <validation-observer ref="informasiPembayaran" tag="form">
                  <customer-payment :fab-form="fabForm"></customer-payment>
                </validation-observer>
              </tab-content>
            
              <!-- Informasi Informasi Pemasangan -->
              <tab-content
                title="Informasi Pemasangan"
                icon="feather icon-info"
                :before-change="validationFormPemasangan"
              >
                <validation-observer ref="informasiPemasangan" tag="form">
                  <customer-information-installation
                    :fab-form="fabForm"
                    :dropdown-time-installation="dropdown_time_installation"
                  ></customer-information-installation>
                </validation-observer>
              </tab-content>
            
              <!-- Preview -->
              <tab-content title="Preview" icon="feather icon-eye">
                <validation-observer ref="preview" tag="form">
                  <preview
                    :fab-form="fabForm"
                    :data-preview="dataPreview"
                    :is-loading-preview="isLoadingPreview"
                  ></preview>
                </validation-observer>
              </tab-content>
            </form-wizard>
          </div>
      </b-card>
    </div>
  </div>
</template>
  <style>
/* @media only screen and (max-width: 1380px) {
    ul.wizard-nav.wizard-nav-pills {
      display: none;
    }
  } */
.steps-transparent.vue-form-wizard .wizard-navigation .wizard-nav {
  pointer-events: none;
}
</style>
  
  <script>
import { FormWizard, TabContent } from "vue-form-wizard";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import axios from "axios";
import interfaces from "@/templates/_interfaces.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import "vue-form-wizard/dist/vue-form-wizard.min.css";

import CustomerInformation from "./registration/steps/CustomerInformation.vue";
import CustomerAddress from "./registration/steps/CustomerAddress.vue";
import CustomerSubscription from "./registration/steps/CustomerSubscription.vue";
import CustomerSubscriptionNew from "./registration/steps/CustomerSubscriptionNew.vue";
import CustomerAttachment from "./registration/steps/CustomerAttachment.vue";
import CustomerPayment from "./registration/steps/CustomerPayment.vue";
import CustomerInformationInstallation from "./registration/steps/CustomerInformationInstallation.vue";
import Preview from "./registration/steps/Preview.vue";
import { isMobile } from "mobile-device-detect";
import { $themeConfig } from "@themeConfig";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    FormWizard,
    TabContent,
    ToastificationContent,
    CustomerInformation,
    CustomerAddress,
    CustomerSubscription,
    CustomerSubscriptionNew,
    CustomerAttachment,
    CustomerPayment,
    CustomerInformationInstallation,
    Preview,
  },
  data() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      admin: localStorage.getItem("admin"),
      requiredValidation: "required", // 'none' 'required'
      fabForm: interfaces.fab,
      registerForm: interfaces.register,
      lineTelephone: 1,
      dropdown_product_list: [],
      dropdown_product_list_new: [],
      dropdown_time_installation: [],
      temp_installation_list: [],
      temp_cart: [],
      dataPreview: [],
      isLoadingPreview: false,
      urlcodeparam: null,
      dropdownAddress: [],
      questioner_result_suggestion: [],
      appLogoImage
    };
  },
  methods: {
    init: function () {
      if (isMobile) {
        var target = document.querySelectorAll(".wizard-nav,.wizard-nav-pills");
        target[0].style = "display:none";
      }
      this.resetForm();
      this.fabForm.email_secondary = "";
      this.fabForm.pic_title = 1;
      this.fabForm.title = 1;
      this.fabForm.lampiran_npwp = 1;
      this.fabForm.lampiran_ktp = 0;
      this.fabForm.lampiran_ipl_pbb = '0';
      this.isLoading = true;
      this.urlcodeparam = this.$route.params.urlcodeparam;
      this.getData();
      this.get_period_time();
    },
    get_period_time: function () {
      axios.get("dropdown/periode-time-installation").then((response) => {
        this.dropdown_time_installation = response.data.data;
      }).catch((error) => {}).finally(() => {});
    },
    getData: function () {
      // axios.defaults.headers.common['Authorization'] = 'Bearer ' + localStorage.getItem('token');
      axios
        .post("self_registration/registration/detail", {
          urlcodeparam: this.urlcodeparam,
        })
        .then((response) => {
          if (response.data.status === 2000) {
            this.fabForm.email = response.data.data.email;
            this.fabForm.phone = response.data.data.phone_number;
            if(this.fabForm.zipcode == "" || this.fabForm.zipcode == null) {
              this.fabForm.zipcode = response.data.data.zipcode;
            }
            this.fabForm.site_name = response.data.data.site_name;
            this.fabForm.code_site = response.data.data.code_site;
            this.fabForm.code_area = response.data.data.code_area;
            this.fabForm.code_group = response.data.data.code_group;
            this.fabForm.code_type = response.data.data.code_type;
            this.fabForm.location = response.data.data.location;
            this.fabForm.location_area = response.data.data.location_area;
            this.fabForm.area_name = response.data.data.area_name;
            this.fabForm.group_name = response.data.data.group_name;
            this.fabForm.cust_type_name = response.data.data.cust_type_name;
            this.fabForm.location_name = response.data.data.location_name;
            this.fabForm.location_area_name =
              response.data.data.location_area_name;
            this.fabForm.registration_code =
              response.data.data.registration_code;

            axios
              .get(
                "self_registration/dropdown/location-address/" +
                  this.fabForm.location_area
              )
              .then((response) => {
                this.dropdownAddress = response.data.location_address;
              });
          } else {
            // this.isLoading = false;
            this.isLoadingPreview = false;
            this.$router.push({ name: "error-404" });
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.isLoadingPreview = true;
        });
    },
    refreshQuestionAnswer(val) {
      this.fabForm.answer = val;
    },
    resetForm: function () {
      for (var i in interfaces.fab) {
        interfaces.fab[i] = null;
      }
      this.fabForm = interfaces.fab;
    },
    get_product_list: function (site, group, type, location) {
      axios
        .post("self_registration/dropdown/product-list", {
          site: site,
          group: group,
          type: type,
          location: location,
        })
        .then((response) => {
          this.dropdown_product_list = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {});
      axios
        .post("self_registration/dropdown/product-list-self-registration", {
          site: site,
          group: group,
          type: type,
          location: location,
          source: 'self_registration'
        })
        .then((response) => {
          this.dropdown_product_list_new = response.data.data;
          this.temp_installation_list = response.data.installation_list;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    refresh_product_dropdown: function (param) {
      this.get_product_list(
        param.area,
        param.group,
        param.type,
        param.location
      );
    },
    get_questioner_result_suggestion: function (answer) {
      axios
        .post(
          "self_registration/questioner_result_suggestion/suggestion_list",
          { answer: answer }
        )
        .then((response) => {
          this.questioner_result_suggestion = response.data.data;
        })
        .catch((error) => {})
        .finally(() => {});
    },
    finalize_selected_product: function (cart) {
      this.temp_cart = cart;
      var temp_selectedProduct = [];
      cart.forEach((list) => {
        temp_selectedProduct.push(list);
      });
      this.fabForm.selected_product = temp_selectedProduct;
      this.fabForm.selected_product.forEach((product) => {
        if (product["installation_array_index"] != null) {
          this.temp_installation_list[product['installation_array_index']]['item_quantity'] = product['item_quantity'];
          this.fabForm.selected_product.push(this.temp_installation_list[product["installation_array_index"]]);
          // console.log(this.temp_installation_list[product["installation_array_index"]]);
        }
      });
      this.fabForm.multiple = {};
      this.fabForm.selected_product.forEach((x) => {
        if (x["multiple_qty"] == 1) {
          this.fabForm.multiple[x.value] = x.item_quantity;
        }
      });
    },
    resetSubscription: function (e) {
      this.fabForm.selected_product = e;
    },
    validationFormInformation() {
      return new Promise((resolve, reject) => {
        //   if(this.admin == 1 ){
        this.$refs.informasiPribadi.validate().then((success) => {
          if (success) {
            if (this.fabForm.title == 5 || this.fabForm.title == 6 || this.fabForm.title == 7) {
              if (this.fabForm.npwp == "000.000.000.0-000.000") {
                alert("please insert valid npwp");
                reject();
              }
              else {
                resolve(true);
              }  
            } else {
              if (this.fabForm.ktp_passport == "1") {
                if (this.fabForm.npwp == "000.000.000.0-000.000" && this.fabForm.ktp == "0000-0000-0000-0000") {
                  alert("please insert at least 1 valid customer identity");
                  reject();
                }
              }
              if (this.fabForm.ktp_passport == "2") {
                if (this.fabForm.npwp == "000.000.000.0-000.000" && this.fabForm.passport == "000000000") {
                  alert("please insert at least 1 valid customer identity");
                  reject();
                }
              }
              resolve(true);
            }
          } else {
            reject();
          }
        });
      });
    },
    validationFormAlamat() {
      this.fabForm.products_customer_view = [];
      this.fabForm.selected_product = [];
      this.temp_cart = []; 
      return new Promise((resolve, reject) => {
        this.$refs.informasiAlamat.validate().then((success) => {
          if (success) {
            this.$refs.custSubs.init();
            axios
              .get(
                "fab/cek-existing/" +
                  this.fabForm.code_site +
                  "/" +
                  this.fabForm.code_group +
                  "/" +
                  this.fabForm.code_type +
                  "/" +
                  this.fabForm.location +
                  "/" +
                  this.fabForm.location_area +
                  "/" +
                  this.fabForm.location_address
              )
              .then((response) => {
                if (response.data.customer.id) {
                  reject();
                  this.fabForm.location_address = null;
                  // this.fabForm.location_area = null;
                  // this.fabForm.location = null;
                  // this.fabForm.code_type = null;
                  // this.fabForm.code_group = null;
                  // this.fabForm.code_area = null;
                  this.fabForm.tax_address = null;
                  this.fabForm.invoice_address = null;
                  alert("Customer already exist");
                } else {
                  resolve(true);
                  this.get_questioner_result_suggestion(this.fabForm.answer);
                  this.get_product_list(
                    this.fabForm.code_site,
                    this.fabForm.code_group,
                    this.fabForm.code_type,
                    this.fabForm.location,
                    this.fabForm.location_area,
                    this.fabForm.location_address
                  );
                  this.getData();
                  this.fabForm.selected_product = null;
                }
              });
          } else {
            reject();
          }
        });
      });
    },
    validationFormLayanan() {
      this.finalize_selected_product(this.temp_cart);
      if (
        this.fabForm.selected_product == null ||
        this.fabForm.selected_product.length == 0
      ) {
        alert("Please Select Subscription Plan");
        return false;
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.informasiLayanan.validate().then((success) => {
            if (success) {
              if (this.fabForm.title == 5 || this.fabForm.title == 6 || this.fabForm.title == 7) {
                this.fabForm.lampiran_npwp = 1;
              } else {
                this.fabForm.lampiran_npwp = 0;
              }
              if(this.fabForm.deposit == "1") {
                this.fabForm.lampiran_ipl_pbb = '0';    
              } else {
                this.fabForm.lampiran_ipl_pbb = '1';
              }
              if (this.fabForm.ktp == "0000-0000-0000-0000" || this.fabForm.passport == '000000000' || this.fabForm.title == 5 || this.fabForm.title == 6 || this.fabForm.title == 7) {
                this.fabForm.lampiran_ktp = 0;
              } else {
                this.fabForm.lampiran_ktp = 1;
              }
              resolve(true);
            } else {
              reject();
            }
          });
        });
      }
    },
    validationFormLampiran() {
      return new Promise((resolve, reject) => {
        this.$refs.informasiLampiran.validate().then((success) => {
          if (
            this.fabForm.lampiran_ktp == "1" &&
            this.fabForm.file_lampiran_ktp === null
          ) {
            alert("KTP should be filled");
            reject();
          } else if (
            this.fabForm.lampiran_npwp == "1" &&
            this.fabForm.file_lampiran_npwp === null
          ) {
            alert("NPWP should be filled");
            reject();
          } else if (
            this.fabForm.lampiran_siup === "1" &&
            this.fabForm.file_lampiran_siup === null
          ) {
            alert("SIUP should be filled");
            reject();
          } else if (
            this.fabForm.lampiran_nib === "1" &&
            this.fabForm.file_lampiran_nib === null
          ) {
            alert("NIB should be filled");
            reject();
          } else if (
            this.fabForm.lampiran_akta_perusahaan === "1" &&
            this.fabForm.file_lampiran_akta === null
          ) {
            alert("AKTA PERUSAHAAN should be filled");
            reject();
          } else if (
            this.fabForm.lampiran_ipl_pbb === "1" &&
            this.fabForm.file_lampiran_ipl_pbb === null
          ) {
            alert("IPL & PBB should be filled");
            reject();
          } else {
            if (success) {
              resolve(true);
            } else {
              reject();
            }
          }
        });
      });
    },
    validationFormPembayaran() {
      return new Promise((resolve, reject) => {
        this.$refs.informasiPembayaran.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationFormPemasangan() {
      return new Promise((resolve, reject) => {
        this.$refs.informasiPemasangan.validate().then((success) => {
          this.isLoading = true;
          const box = document.getElementsByClassName(
            "wizard-card-footer clearfix"
          )[0];
          box.style.visibility = "hidden";
          if (success) {
            this.isLoadingPreview = true;
            axios
              .post("fab/preview", this.fabForm)
              .then((response) => {
                this.dataPreview = response.data.data;
                this.dataPreview.status_lokasi =
                  this.fabForm.status_lokasi == "1" ? "Pribadi" : "Sewa";
                this.dataPreview.jenis_pembayaran =
                  this.fabForm.payment_type == 1
                    ? "Virtual Account"
                    : "AutoDebit";
                this.dataPreview.periode_berlangganan =
                  this.fabForm.subscription_periode == 1
                    ? "Bulanan"
                    : "Lainnya";
                this.dataPreview.file_ktp = response.data.file_ktp;
                this.dataPreview.file_akta_perusahaan =
                  response.data.file_akta_perusahaan;
                this.dataPreview.file_npwp = response.data.file_npwp;
                this.dataPreview.file_siup = response.data.file_siup;
                this.dataPreview.file_sk_domisili =
                  response.data.file_sk_domisili;
                this.dataPreview.file_tdp = response.data.file_tdp;
                this.dataPreview.file_nib = response.data.file_nib;
                this.dataPreview.file_ipl = response.data.file_ipl;

                this.fabForm.products_customer_view.forEach(list => {
                const substringToCheck = 'FTTC';
                if (list['text'].toUpperCase().includes(substringToCheck)) {
                  this.dataPreview.showFttcWording = true;
                }
              });
              })
              .catch((error) => {})
              .finally(() => {
                const box = document.getElementsByClassName(
                  "wizard-card-footer clearfix"
                )[0];
                box.style.visibility = "visible";
                this.isLoadingPreview = false;
              });
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    formSubmitted() {
      if (this.fabForm.terms == "accepted") {
        if (this.fabForm.selfie != null) {
          if (confirm("Are you sure want to Add this data ?")) {
            this.isLoadingPreview = true;
            const box = document.getElementsByClassName(
              "wizard-card-footer clearfix"
            )[0];
            box.style.visibility = "hidden";
            // this.fabForm.registration_code = this.registerForm.registration_code
            this.fabForm.questioner = this.answer;
            axios
              .post("fab/add", this.fabForm)
              .then((response) => {
                if (response.data.status == 2000) {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add successful",
                      icon: "EditIcon",
                      variant: "success",
                      text: response.data.message,
                    },
                  });
                  this.$router.push({ path: "/succes" });
                } else {
                  this.$toast({
                    component: ToastificationContent,
                    props: {
                      title: "Form Add Failed",
                      icon: "EditIcon",
                      variant: "warning",
                      text: response.data.message,
                    },
                  });
                }
              })
              .catch((error) => {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Form Add Failed",
                    icon: "EditIcon",
                    variant: "danger",
                    text: error.message,
                  },
                });
                this.$router.push({ path: `/expired/${this.urlcodeparam}` });
              })
              .finally(() => {
                const box = document.getElementsByClassName(
                  "wizard-card-footer clearfix"
                )[0];
                box.style.visibility = "visible";
                this.isLoadingPreview = false;
              });
          } else {
            // if (confirm("Are you sure want to Cancel ? your file upload will be remove")) {
            // }
          }
        } else {
          alert("Please Upload Selfie Photo");
        }
      } else {
        alert("Please Accept Terms and Condition");
      }
    },
  },
  mounted() {
    this.init();
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/libs/vue-wizard.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
  